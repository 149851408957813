 @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

 body {
     margin: 0;
     padding: 0;
     background: #e1e1e1;
 }

 div,
 p,
 a,
 li,
 td {
     -webkit-text-size-adjust: none;
 }

 body {
     width: 100%;
     height: 100%;
     background-color: #e1e1e1;
     margin: 0;
     padding: 0;
     -webkit-font-smoothing: antialiased;
 }

 html {
     width: 100%;
 }

 p {
     padding: 0 !important;
     margin-top: 0 !important;
     margin-right: 0 !important;
     margin-bottom: 0 !important;
     margin-left: 0 !important;
 }

 .fullTable{
     margin-top: -1px;
 }