.auth-login-box{
	background: url("../images/login-bg.svg") no-repeat center left;
}
.auth-register-box{
    background: url("../images/register-bg.svg") no-repeat center center/cover;
    background-attachment: fixed;
    padding-top: 50px;
}

.auth-right-blue {
	position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    background: #E5EFF0;
    padding-left: 15px;
}
.resetPassword-box .auth-right-blue,
.otp-box .auth-right-blue,
.createNewPass-box .auth-right-blue{
    display: flex;
    align-items: center;
    justify-content: center;
}
.resetPassword-box .auth-right-blue img,
.otp-box .auth-right-blue img,
.createNewPass-box .auth-right-blue img{
    margin-top: 120px;
}
.auth-bg-slideshow {
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}
.auth-bg-slideshow .mover {
    height: 6000px;
    width: 100%;
    background: url(../images/login-tiles.png);
    background-size: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    animation: moveSlideshow 150s linear infinite;
}
@keyframes moveSlideshow {
   100% {
      -webkit-transform: translateY(-66.6666%);
      -moz-transform: translateY(-66.6666%);
      -ms-transform: translateY(-66.6666%);
      transform: translateY(-66.6666%);
   }
}
.auth-login-box {
    height: calc(100vh - 124px);
}
.auth-white-box{
    background: #fff;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(155,164,180,0.16);
    -moz-box-shadow: 0px 0px 40px 0px rgba(155,164,180,0.16);
    box-shadow: 0px 0px 40px 0px rgba(155,164,180,0.16);
	border: 2px solid #E5EFF0;
}
.auth-register-box .auth-white-box{
    padding: 20px 25px 30px;
    width: 950px;
    margin: auto;
    border-radius: 20px 20px 0 0;
    position: relative;
}
.auth-login-box .auth-white-box{
	border-radius: 20px;
	padding: 20px 30px 40px;
	width: 500px;	
}
.auth-back a {
    font-size: 18px;
    color: #9BA4B4;
    position: absolute;
    left: 35px;
    top: 40px;
}
.auth-back a:hover{
    color: #FF8805;
}
.auth-back a i{
    margin-right: 5px;
}
.m_header{
	margin-bottom: 20px;
}

.m_header h2 {
    margin-bottom: 10px;
    font-weight: normal;
}

.m_header p {
    font-size: 24px;
    color: #1B262C;
    margin: 0;
}

.form-group{
	margin-bottom: 20px;
}
.resetPassword-box .auth-form .form-group {
    padding: 30px 0 40px;
}
.form-control{
	border: 1px solid #E5EFF0;
	border-radius: 5px;
	height: 60px;
    padding: 6px 20px;
	background: #FBFBFB;
}

.password-group{
	position: relative;
}

.password-eye{
	    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    right: 20px;
    color: #9BA4B4;
    background: transparent;
    border: none;
    padding: 0;
}

.auth-block .forgot-link {
    margin-bottom: 40px;
    -webkit-display: flex;
    -moz-display: flex;
    display: flex;
    justify-content: space-between;
}
.custom-check-block {
    position: relative;
}
.custom-check-block .custom-check-label {
    font-size: 16px;
    padding-left: 35px;
    margin: 0;
    color: #9BA4B4;
}
.custom-check-block .custom-check-label a{
    color: #EF4339;
}
.custom-check-block .custom-check-label:after, .custom-check-block .custom-check-label:before {
    content: "";
    position: absolute;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    top: 50%;
    -webkit-transform: translate(0px, -50%);
    -moz-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
}
.custom-check-block .custom-check-label:after {
    height: 25px;
    width: 25px;
    border: 1px solid #DAEBFD;
    background: #fff;
    left: 0;
}
.custom-check-block input:checked ~ .custom-check-label:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    z-index: 8;
    left: 5px;
    color: #fff;
}
.custom-check-block input:checked ~ .custom-check-label:after {
    border-color: #FF8805;
    background: #FF8805;
}

.auth-block .forgot-link a {
    color: #0779E4;
    text-decoration: underline;
}

.auth-block .forgot-link a:hover{
	color: #EF4339;
}

.middleLine-sep {
    text-align: center;
    margin: 40px 0 30px;
    font-size: 14px;
    position: relative;
    width: 100%;
}
.middleLine-sep:before {
    background-color: #E5EFF0;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    vertical-align: middle;
    width: 100%;
    top: 11px;
}
.middleLine-sep span {
    display: inline-block;
    color: #9BA4B4;
    padding: 0 20px;
    margin: auto;
    text-align: center;
    font-size: 16px;
    background: #fff;
    position: relative;
    z-index: 2;
}

.social-login, .social-login a {
    display: flex;
    align-items: center;
    
}
.social-login{
	flex-wrap: wrap;
}
.social-login a {
	background: #fff;
	padding: 15px 20px;
	font-size: 16px;
	color: #9BA4B4;
	border-radius: 100px;
	width: calc(33.33% - 12px);
	margin-right: 18px;
}
.social-login a:hover{
	color: #fff;
}
.social-login a.fb-login{
	border: 2px solid #345BFC;
}
.social-login a.fb-login:hover{
	background: #345BFC;
}
.social-login a.apple-login{
	border: 2px solid #1B262C;
}
.social-login a.apple-login:hover{
	background: #1B262C;
}
.social-login a.google-login{
	border: 2px solid #FF8805;
}
.social-login a.google-login:hover{
	background: #FF8805;
}
.social-login a img {
    margin-right: 10px;
}
.social-login a:hover img{
	filter: brightness(0) invert(1);
}
.social-login a:last-child {
    margin-right: 0;
}
.bottom-info {
    margin-top: 40px;
}
.bottom-info p {
    font-size: 18px;
    color: #646464;
    margin: 0;
    text-align: center;
}
.bottom-info p a {
    color: #FF8805;
    font-weight: bold;
}









.profileUp-inner{
    display: flex;
    align-items: center;
}
.cus-fileUpload-label {
    cursor: pointer;
}
.profileUp-inner .upload-here{
    background: #FBFBFB;
    width: 280px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #E5EFF0;
    border-radius: 5px;
}

.profileUp-inner .upload-content{
    margin-left: 20px;
}

.profileUp-inner .upload-content h5{
    color: #1B262C;
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: normal;
    display: flex;
}
.profileUp-inner .upload-content h5 span {
    color: red;
    font-size: 18px;
}
.profileUp-inner .upload-content span{
    font-size: 18px;
    color: #9BA4B4;
    display: block;
}

.auth-register-box .auth-form .reg-form-tab{
    display: none;
    flex-wrap: wrap;
}
.cmr-form-block .form-group,
.auth-register-box .auth-form .form-group{
    margin: 0 10px 20px;
}
.group-12{
    width: 100%;
}
.group-8{
    width: calc(80% - 20px);
}
.group-6{
    width: calc(50% - 20px);
}
.group-3{
    width: calc(25% - 20px);
}
.group-4{
    width: calc(33.33% - 20px);
}

.form-group label{
    color: #1B262C;
    font-size: 20px;
    margin-bottom: 15px;
}

.radio-block, .radio-label-control{
    display: flex;
    align-items: center;
}

.radio-label-control{
    position: relative;
    margin-right: 34px;
    cursor: pointer;
}
.radio-block .radio-label-control{
    color: #1B262C;
    font-size: 18px;
}
.radio-label-control:before,
.radio-label-control:after{
    content: "";
    display: block;
}

.radio-label-control:before{
    width: 22px;
    height: 22px;
    border-radius: 100px;
    border: 1px solid #9BA4B4;
    margin-right: 10px;
    background: #fff;
}
.radio-label-control:after{
    width: 12px;
    height: 12px;
    border-radius: 100px;
    background: #9BA4B4;
    position: absolute;
    left: 5px;
}
.radio-input:checked ~ .radio-label-control:before{
    border-color: #000000;   
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1); 
}
.radio-input:checked ~ .radio-label-control:after{
    background: #FF8805;  
}

.auth-register-box .form-submit{
    margin-top: 40px;
}



.phoneNumber-group {
    display: flex;
    align-items: center;
}
.phoneNumber-group .phoneNo-code-block select.form-control {
    border-radius: 5px 0 0 5px;
    border-right: none;
    width: 100px;
}
.phoneNumber-group input.form-control {
    border-radius: 0 5px 5px 0;
    border-left: 0;
    padding-left: 5px;
}




.reg-tabNav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 30px;
}

.reg-tabNav .step-item{
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    padding: 14px 0;
    color: #1B262C;
}

.reg-tabNav .step-item.active{
    color: #8BCDCD;
}

.reg-tabNav .step-item:after,
.reg-tabNav .step-item:before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    display: block;
}

.reg-tabNav .step-item:after{
    background: #E5EFF0;
}

.reg-tabNav .step-item.active:before{
    background: #8BCDCD;
    z-index: 2;
}


.step .step-name {
    display: inline-block;
    position: relative;
}

.step2-now.step .step-name:after, .step2-now.step .step-name:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
}

.step2-now.step .step-name:after{
    width: 18px;
    height: 18px;
    border-radius: 100px;
    display: block;
    background: #8BCDCD;
    left: -26px;
}
.step2-now.step .step-name:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    color: #fff;
    left: -22px;
    font-size: 9px;
    z-index: 2;
}










.auth-accordion .card {
  border: none;
}
.auth-accordion .card:not(:last-child) {
    border-bottom: 1px solid #D2D2D2;
    border-radius: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.auth-accordion .card-head h3{
  cursor: pointer;
  position: relative;
  font-size: 20px;
  font-weight: normal;
  padding: 20px 0;
  margin: 0 10px;
}
.auth-accordion .card-head h3:after{
    content: "\f077";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    font-size: 20px;
    position: absolute;
    right: 0;
    transition: all 0.3s ease-in-out;
    color: #EF4339;
}
.auth-accordion .card-head h3.collapsed:after{
    transform: rotate(-180deg);
    color: #9BA4B4;
}
.auth-accordion .auth-card-body {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
}
.group-title h4{
    font-size: 20px;
    font-weight: normal;
    padding: 20px 0;
}
.primary-radio-group label{
    font-size: 20px;
    padding-top: 8px;
}

.auth-success-section{
    background: url("../images/thanks-bg.svg") no-repeat center center/cover;
    height: calc(100vh - 124px);
    padding-top: 60px;
}
.auth-success-section .success-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.success-inner .success-content{
    margin: auto;
}
.auth-success-section .success-content h1{
    font-size: 42px;
    font-weight: normal;
    color: #1B262C;
    margin-bottom: 9px;
}
.auth-success-section .success-content p{
    font-size: 24px;
    margin-bottom: 40px;
}

.auth-success-section .success-content p span{
    color: #EF4339;
    font-weight: bold;
}

.auth-success-section .success-img{
    margin-top: auto;
}










.fg-place{
    position: relative;
}
.form-group .placeholder {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0px, -50%);
    color: #9BA4B4;
    display: none;
}
.form-group .placeholder label {
    color: #9BA4B4;
    font-size: 18px;
    margin: 0;
    font-weight: normal;
}
.form-group .placeholder .star {
    color: red;
    font-size: 18px;
}

.otp-form-group {
    display: flex;
    align-items: center;
}
.otp-form-group .form-control {
    text-align: center;
}
.otp-form-group .form-control + .form-control {
    margin-left: 15px;
}


.otp-resend {
    text-align: right;
    padding-bottom: 40px;
}
.otp-resend .btn {
    padding: 0;
    font-size: 15px;
    color: #646464;
}
.otp-resend .btn img {
    margin-left: 6px;
    width: 19px;
    vertical-align: sub;
}
.otp-box .otp-form-group {
    padding: 30px 0 0;
}

.createNewPass-box .auth-form {
    padding-top: 15px;
}

.createNewPass-box .form-submit {
    padding-top: 20px;
}


.password-group .form-control {
    padding-right: 46px;
}