.main-header {
	width: 100%;
	padding: 20px 0;
	-webkit-transition: all linear .5s;
	-moz-transition: all linear .5s;
	-ms-transition: all linear .5s;
	-o-transition: all linear .5s;
	transition: all linear .5s;
	z-index: 99;
	position: absolute;
	
}

.navbar-top-transparent{
	background: transparent;
}

.navbar-top-dark{
	background: #000;
	position: relative;
}

body .main-header.sticky {
    position: fixed;
    background: #000;

}



body:not(.dark-header) .main-header{
	position: absolute;
	top: 0;
	left: 0;
	background-color: transparent;
	z-index: 9999;
}
/* body.dark-header .main-header{
	background: transparent;
} */
.main-header.sticky-1 {
	padding: 5px 0
}

.main-header.sticky-1:not(.sticky) {
	-webkit-transform: translateY(-300px);
	-moz-transform: translateY(-300px);
	-ms-transform: translateY(-300px);
	-o-transform: translateY(-300px);
	transform: translateY(-300px)
}

body .main-header.sticky {
	position: fixed;
	background: #000;
}

.main-header.sticky:not(.menu--opend) .arrow-icon:before,
.main-header.sticky:not(.menu--opend) .arrow-icon:after {
	background-color: #fff
}

.brand-name a {
	display: inline-block;
	vertical-align: middle
}

/*.main-header.sticky .brand-name img,*/
.main-header.menu--opend .brand-name img {
	-webkit-filter: brightness(0);
	filter: brightness(0);
}
.main-header.menu--opend .brand-name img,
.main-header.sticky .brand-name img{
	width: 62px;
}

.responsive-menu--opened .main-header.sticky-1 .brand-name img {
	-webkit-filter: brightness(1) invert(0);
	filter: brightness(1) invert(0);
}

.responsive-menu--opened .main-header.sticky-1 .contact-info button {
	border-color: #000;
	color: #000;
}

.main-header:not(.menu--opend) .header-right>ul>li>a {
	color: #fff
}
@media (min-width: 1200px){
	.main-header:not(.menu--opend) .header-right>ul>li>a.active {
    	color: #FF8805;
	}
}
.header-right ul.main-menu > li > a:not(.cus_btn).active-menu {
    color: #FF8805;
}
.header-right ul.main-menu > li > a:not(.cus_btn).active-menu .arrow-icon:before, .header-right ul.main-menu > li > a:not(.cus_btn).active-menu .arrow-icon:after {
    background: #FF8805;
}
.header-right ul.main-menu > li > a:not(.cus_btn) {
    font-size: 18px;
}
.main-header:not(.sticky):not(.menu--opend) .header-right a .arrow-icon:before,
.main-header:not(.sticky):not(.menu--opend) .header-right a .arrow-icon:after {
	background-color: #fff
}
.user-btn {
    background: #EF4339;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
}
.dropdown-toggle.user-btn:after {
    display: none;
}
.user-btn .userBtn-icon img {
    border-radius: 100px;
    border: 1px solid #E5EFF0;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.user-name {
    font-size: 17px;
    font-weight: 600;
    color: #fff;
}
.user-name i {
    margin-left: 15px;
    font-size: 15px;
}
.user-menu .dropdown-menu {
    width: 300px;
	top: calc(100% + 20px) !important;
    right: 0;
    left: auto !important;
    border-radius: 5px;
    border-color: #E5EFF0;
    padding: 0;
    margin: 0;
	transform: none !important;
}
@media(min-width: 1200px){
	.user-menu .dropdown-menu{
		width: 220px;
	}
}
.user-menu .dropdown-menu:after{
	content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 16px solid #fff;
    position: absolute;
    top: -17px;
    right: 25px;
}
.user-menu .dropdown-menu .dropdown-item {
    padding: 11px 20px;
    display: flex;
    justify-content: space-between;
}
.user-menu .dropdown-menu .dropdown-item:hover {
    background: #EF4339;
    color: #fff;
}
.user-menu .dropdown-menu .dropdown-item:hover img {
    filter: brightness(0) invert(1);
}
.header-right ul li.bell-btn .bell-nav-toggle i {
    font-size: 20px;
}
.header-right ul li.bell-btn .bell-nav-toggle .notif-circle {
    width: 12px;
    height: 12px;
    background: #EF4339;
    display: block;
    border-radius: 100px;
    position: absolute;
    top: 0;
    right: -3px;
}
.header-right ul li.bell-btn .bell-nav-toggle{
    position: relative;
	padding: 0;
	color: #fff;
}
.bell-btn .dropdown-toggle:after{
	display: none;
}
.responsive-menu--opened  .header-right ul li.bell-btn .bell-nav-toggle {
    color: #333;
}



.notify-drop-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 14px;
}

.notify-drop-head h4 {
    color: #9BA4B4;
    font-size: 18px;
    font-weight: 500;
}

.notify-drop-head .notify-markRead-btn {
    padding: 0;
    border: none;
    background: transparent;
    color: #EF4339;
    font-size: 18px;
    font-weight: 500;
}

.bell-btn .dropdown-menu {
    border: 1px solid #E5EFF0;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    width: 430px;
    top: calc(100% + 25px) !important;
    right: -13px;
    left: auto !important;
    transform: none !important;
}
.bell-btn .dropdown-menu .dropdown-menu-inner .notify-list{
	height: 500px;
    /*overflow-y: auto;*/
}
.notify-item .ni-icon span {
    background: #EF4339;
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: block;
    text-align: center;
    line-height: 40px;
    color: #fff;
}
.notify-item .ni-icon {
    margin-right: 10px;
}
.notify-item {
    display: flex;
    padding: 15px 25px 18px 20px;
	margin-bottom: 3px;
	cursor: pointer;
}
.notify-item:last-child{
	margin-bottom: 0;
}
.ni-content p {
    font-size: 18px;
    color: #646464;
    font-weight: normal;
    margin-bottom: 10px;
}
.ni-content p strong {
    font-weight: bold;
    color: #1B262C;
}
.bell-btn {
    position: relative;
}
.ni-content .ni-time {
    color: #9BA4B4;
    font-size: 16px;
    display: block;
}
 .unread-notify {
    background: rgb(239,67,57,0.07);
}
.bell-btn .dropdown-menu:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 16px solid #fff;
    position: absolute;
    top: -17px;
    right: 25px;
    z-index: 9999999;
}
.notify-item .ni-content {
    position: relative;
}

.ni-content .notify-delete {
    background: transparent;
    border: none;
    padding: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #9BA4B4;
}






.header-right {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	width: 100%;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: flex-end
}

.header-right ul {
	padding: 0;
	margin: 0;
	list-style: none
}

.header-right ul li:not(.user-btn-item) {
	display: inline-block;
	padding: 0 10px;
	vertical-align: middle
}

.header-right a {
	color: #333;
}

.header-right ul li a:not(.dropdown-item){
	padding: 10px 0;
	position: relative;
	-webkit-transition: all ease-in-out .4s;
	-moz-transition: all ease-in-out .4s;
	-ms-transition: all ease-in-out .4s;
	-o-transition: all ease-in-out .4s;
	transition: all ease-in-out .4s
}

.main-header.menu--opend {
	background-color: transparent;
	border-bottom: 1px solid #ddd;
	position: fixed;
	padding: 7px 0;
	-ms-transform: none!important;
	-webkit-transform: none!important;
	-moz-transform: none!important;
	-o-transform: none!important;
	transform: none!important
}

.header-right ul li .menuDropdown .menu-block {
	padding: 15px;
	/*margin-top: 20px;*/
	border-right: 1px solid #ddd;
	opacity: 0;
	transform: translate(0, -30%);
	transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1) 0ms
}

.header-right ul li .dropiMenu.active~.menuDropdown .menu-block {
	opacity: 1;
	transform: translate(0, 0)
}

/*.header-right ul li .dropiMenu.active~.menuDropdown .menu-block.menu-block-1 {
	transition-delay: 100ms
}

.header-right ul li .dropiMenu.active~.menuDropdown .menu-block.menu-block-2 {
	transition-delay: 200ms
}

.header-right ul li .dropiMenu.active~.menuDropdown .menu-block.menu-block-3 {
	transition-delay: 300ms
}

.header-right ul li .dropiMenu.active~.menuDropdown .menu-block.menu-block-4 {
	transition-delay: 400ms
}*/

.header-right ul li .menuDropdown .menu-block:last-child,
.header-right ul li .menuDropdown .menu-block:nth-child(5) {
	border-right: 0
}

.header-right ul li .menuDropdown {
	top: 0;
	position: fixed;
	left: 0;
	background-color: #fff;
	z-index: -2;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	display: block;
	background-color: #fcfcfc;
	visibility: hidden;
	z-index: -2;
	transform: translate(0, -115%);
	transition: transform 500ms cubic-bezier(0.55, 0, 0.1, 1) 0ms, visibility 0ms linear 333.33333333ms, background-color 0ms linear 0ms;
	font-size: 14px;
	max-height: 640px;
	/* overflow: hidden; */
	padding-top: 69px;
}

.main-header.menu--opend:after {
	content: '';
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	height: 1px;
	background-color: #ddd;
	margin-top: -1px
}

.header-right ul li .dropiMenu.active~.menuDropdown {
	visibility: visible;
	background-color: white;
	transform: translate(0, 0);
	transition: transform 500ms cubic-bezier(0.55, 0, 0.1, 1) 0ms, visibility 0ms linear 0ms, background-color 0ms linear 500ms
}

.header-right ul li .menuDropdown .sub-menu {
	margin-top: 7px;
	padding: 0;
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative;
	align-content: flex-start
}

.header-right ul li .menuDropdown .sub-menu li {
	width: 100%;
	padding: 0;
	margin: 0
}

.header-right ul li .menuDropdown .sub-menu li a {
	width: 100%;
	display: inline-block;
	vertical-align: bottom;
	padding: 5px 0
}

.header-right ul li .menuDropdown .sub-menu li a:before {
	content: none
}

.header-right ul li .menuDropdown .menu-heading {
	width: 100%;
	text-align: center;
	display: inline-block;
	font-size: 16px;
	padding-top: 0;
	padding-bottom: 0;
	/*margin-bottom: 10px*/
}

.header-right ul li .menuDropdown .menu-heading:before {
	content: none
}

.header-right ul li .menuDropdown .menu-heading div {
	display: block;
	margin-bottom: 5px;
	font-size: 13px;
	color: #6c757d
}

.header-right ul li .menuDropdown .menu-heading div.icon img {
	width: 40px
}

.header-right ul li .menuDropdown .sub-menu .menu-has-children {
	position: static
}

.header-right ul li .menuDropdown .sub-menu .sub-menu-child {
	position: absolute;
	top: 30px;
	left: 0;
	width: 100%;
	transform: translate(100%, 0);
	opacity: 0;
	visibility: hidden;
	overflow-y: auto;
	max-height: 0
}

 /* Hide scrollbar for Chrome, Safari and Opera */
 .header-right ul li .menuDropdown .sub-menu .sub-menu-child::-webkit-scrollbar {
	display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
 .header-right ul li .menuDropdown .sub-menu .sub-menu-child {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
  } 

.header-right ul li .menuDropdown .sub-menu .sub-menu-child.subchild-show {
	transform: translate(0, 0);
	opacity: 1;
	visibility: visible;
	transition: all 300ms cubic-bezier(0.55, 0, 0.1, 1) 100ms;
	max-height: initial;
	position: relative;
	top: 0;
	height: 100%
}

.header-right ul li .menuDropdown .sub-menu.submenu-open>li {
	opacity: 0;
	height: 0!important;
	overflow: hidden
}

.header-right ul li .menuDropdown .sub-menu.submenu-open>li.subchild-open {
	height: auto!important;
	opacity: 1
}

.menubackdrop {
	position: fixed;
	top: 100px;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: -3;
	display: none
}

.sticky .menubackdrop {
	top: 54px
}

.dropiMenu.active~.menubackdrop {
	display: block
}

.toogleLink {
	position: relative;
	padding-right: 20px
}

.arrow-icon {
	position: absolute;
	top: 50%;
	right: 0;
	width: 20px;
	height: 20px;
	margin-top: -12px
}

.arrow-icon:before {
	content: '';
	width: 7px;
	height: 2px;
	background-color: #333;
	position: absolute;
	top: 7px;
	right: 0;
	transform: rotate(45deg);
	transition: all .1s
}

.arrow-icon:after {
	content: '';
	width: 7px;
	height: 2px;
	background-color: #333;
	position: absolute;
	top: 11px;
	right: 0;
	transform: rotate(-45deg);
	transition: all .3s
}

.toogleLink.active .arrow-icon:before,
.toogleLink.active .arrow-icon:after,
.dropiMenu.active .arrow-icon:before,
.dropiMenu.active .arrow-icon:after {
	width: 9px
}

.toogleLink.active .arrow-icon:before,
.toogleLink.active .arrow-icon:after,
.dropiMenu.active .arrow-icon:before,
.dropiMenu.active .arrow-icon:after {
	top: 10px;
	background-color: #FF8805;
}

.dropiMenu .arrow-icon {
	transform: rotate(90deg);
	margin-top: -15px;
	right: -5px
}

.dropiMenu {
	padding-right: 20px!important
}

.dropiMenu.active {
	color: #FF8805 !important;
}

.header-right ul li .menuDropdown .more-link a {
	color: #FF8805;
}

.more-link .arrow-icon:before,
.more-link .arrow-icon:after {
	background-color: #FF8805;
}

.main-header.sticky-1,
.main-header.menu--opend,
.responsive-menu--opened .main-header {
	top: 0
}


@media(max-width:1440px) {
	.header-right ul li:not(.user-btn-item){
		padding: 0 3px;
	}
	.header-right ul.main-menu > li > a:not(.cus_btn) {
    	font-size: 17px;
	}
	.header-right ul li .menuDropdown .menu-heading{
		font-size: 15px;
	}
}

@media(max-width:1366px) {
	.main-header {
		padding: 14px 0
	}
	.main-header:not(.sticky):not(.menu--opend) .brand-name img{
		width: 80px;
	}
	.header-right ul li .menuDropdown .menu-heading div {
		font-size: 12px
	}
	.header-right ul li:not(.user-btn-item) {
        padding: 0 6px;
    }
}

@media (max-width: 1280px){
	.header-right ul li {
    	padding: 0 9px;
	}
    .header-right ul li:not(.user-btn-item) {
        padding: 0 8px;
    }
    .user-btn {
        padding: 5px 8px;
    }
    .user-name {
        font-size: 12px;
    }
    .user-name i {
        font-size: 14px;
        margin-left: 8px;
    }
}

 /* Hide scrollbar for Chrome, Safari and Opera */
 .header-right ul li .menuDropdown .sub-menu::-webkit-scrollbar {
	display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .header-right ul li .menuDropdown .sub-menu {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
  } 

@media(min-width:1200px) {
	.header-right ul li .menuDropdown .menu-block:nth-child(1), .header-right ul li .menuDropdown .menu-block:nth-child(2), .header-right ul li .menuDropdown .menu-block:nth-child(3), .header-right ul li .menuDropdown .menu-block:nth-child(4), .header-right ul li .menuDropdown .menu-block:nth-child(5) {
    	border-bottom: 1px solid #ddd;
	}
	.header-right ul li .menuDropdown .sub-menu {
		height: 170px;
		overflow: auto;
		overflow-x: auto;
	}
	.header-right ul li .menuDropdown .sub-menu .sub-menu-child.subchild-show {
		max-height: 140px
	}
	.menu-block.menu-block-1 .menu-heading,
	.menu-block.menu-block-1 .sub-menu li a:hover,
	.menu-block.menu-block-1 .toogleLink.active {
		color: #FF8805;
	}
	.menu-block.menu-block-2 .menu-heading,
	.menu-block.menu-block-2 .sub-menu li a:hover,
	.menu-block.menu-block-2 .toogleLink.active {
		color: #8BCDCD;
	}
	.menu-block.menu-block-3 .menu-heading,
	.menu-block.menu-block-3 .sub-menu li a:hover,
	.menu-block.menu-block-3 .toogleLink.active {
		color: #2242C2;
	}
	.menu-block.menu-block-4 .menu-heading,
	.menu-block.menu-block-4 .sub-menu li a:hover,
	.menu-block.menu-block-4 .toogleLink.active {
		color: #0779E4;
	}
	.menu-block.menu-block-5 .menu-heading,
	.menu-block.menu-block-5 .sub-menu li a:hover,
	.menu-block.menu-block-5 .toogleLink.active {
		color: #FFA5A5;
	}
	.menu-block.menu-block-6 .menu-heading,
	.menu-block.menu-block-6 .sub-menu li a:hover,
	.menu-block.menu-block-6 .toogleLink.active {
		color: #FFBD43;
	}
	.menu-block.menu-block-7 .menu-heading,
	.menu-block.menu-block-7 .sub-menu li a:hover,
	.menu-block.menu-block-7 .toogleLink.active {
		color: #FF5733;
	}
	.menu-block.menu-block-8 .menu-heading,
	.menu-block.menu-block-8 .sub-menu li a:hover,
	.menu-block.menu-block-8 .toogleLink.active {
		color: #208582;
	}
	.menu-block.menu-block-9 .menu-heading,
	.menu-block.menu-block-9 .sub-menu li a:hover,
	.menu-block.menu-block-9 .toogleLink.active {
		color: #484898;
	}
	.menu-block.menu-block-10 .menu-heading,
	.menu-block.menu-block-10 .sub-menu li a:hover,
	.menu-block.menu-block-10 .toogleLink.active {
		color: #900C3E;
	}
	.menu-block.menu-block-1 .toogleLink.active .arrow-icon:before,
	.menu-block.menu-block-1 .toogleLink.active .arrow-icon:after {
		background-color: #FF8805;
	}
	.menu-block.menu-block-2 .toogleLink.active .arrow-icon:before,
	.menu-block.menu-block-2 .toogleLink.active .arrow-icon:after {
		background-color: #8BCDCD;
	}
	.menu-block.menu-block-3 .toogleLink.active .arrow-icon:before,
	.menu-block.menu-block-3 .toogleLink.active .arrow-icon:after {
		background-color: #2242C2;
	}
	.menu-block.menu-block-4 .toogleLink.active .arrow-icon:before,
	.menu-block.menu-block-4 .toogleLink.active .arrow-icon:after {
		background-color: #0779E4;
	}
	.menu-block.menu-block-5 .toogleLink.active .arrow-icon:before,
	.menu-block.menu-block-5 .toogleLink.active .arrow-icon:after {
		background-color: #FFA5A5;
	}
	.menu-block.menu-block-6 .toogleLink.active .arrow-icon:before,
	.menu-block.menu-block-6 .toogleLink.active .arrow-icon:after {
		background-color: #FFBD43; 
	}
	.menu-block.menu-block-7 .toogleLink.active .arrow-icon:before,
	.menu-block.menu-block-7 .toogleLink.active .arrow-icon:after {
		background-color: #FF5733;
	}
	.menu-block.menu-block-8 .toogleLink.active .arrow-icon:before,
	.menu-block.menu-block-8 .toogleLink.active .arrow-icon:after {
		background-color: #208582;
	}
	.menu-block.menu-block-9 .toogleLink.active .arrow-icon:before,
	.menu-block.menu-block-9 .toogleLink.active .arrow-icon:after {
		background-color: #484898;
	}
	.menu-block.menu-block-10 .toogleLink.active .arrow-icon:before,
	.menu-block.menu-block-10 .toogleLink.active .arrow-icon:after {
		background-color: #900C3E;
	}
	.menu-block.menu-block-1 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #FF8805;
	}
	.menu-block.menu-block-2 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #8BCDCD;
	}
	.menu-block.menu-block-3 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #2242C2;
	}
	.menu-block.menu-block-4 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #0779E4;
	}
	.menu-block.menu-block-5 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #FFA5A5;
	}
	.menu-block.menu-block-6 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #FFBD43; 
	}
	.menu-block.menu-block-7 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #FF5733;
	}
	.menu-block.menu-block-8 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #208582;
	}
	.menu-block.menu-block-9 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #484898;
	}
	.menu-block.menu-block-10 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #900C3E;
	}
}

@media screen and (max-width:1199px) {
	.header-right ul.main-menu > li:first-child{
		display: none;
	}
	.header-right ul li {
    	padding: 0 7px;
	}
	div.menuDropdown>.container {
		max-width: 100%
	}
	.header-right ul li .menuDropdown .menu-heading div {
		font-size: 12.5px
	}
	.responsive-menu--opened .main-header:not(.sticky):not(.menu--opend) .brand-name img {
		-webkit-filter: brightness(0);
		filter: brightness(0);
		width: 63px;
	}
	.responsive-menu--opened .main-header:not(.sticky):not(.menu--opend) .contact-info button {
		border-color: #012046;
		color: #012046
	}
	.header-right ul li.menuToggle {
		padding: 0 0 0 15px
	}
	.main-header {
		padding: 10px 0
	}
	.main-header.sticky-1 {
		padding: 10px 0
	}
	.main-header.sticky-1:not(.sticky) {
		-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0)
	}
	.main-header.menu--opend {
		border-bottom: 0
	}
	.main-header.menu--opend:after {
		content: none
	}
	#main-menu {
		position: fixed;
		top: 73px;
		left: 0;
		width: 100%;
		background-color: #000;
		overflow: hidden;
		overflow-y: auto;
		height: calc(100vh - 73px);
		transform: translate(0, -115%);
		transition: transform 333.33333333ms cubic-bezier(0.55, 0, 0.1, 1) 0ms, visibility 0ms linear 333.33333333ms, background-color 0ms linear 0ms;
		visibility: hidden;
		padding: 0
	}
	.responsive-menu--opened {
		overflow: hidden!important
	}
	body.responsive-menu--opened .main-header {
		background-color: #fff;
		z-index: 99999999
	}
	.responsive-menu--opened #main-menu {
		visibility: visible;
		transform: translate(0, 0);
		transition: transform 500ms cubic-bezier(0.55, 0, 0.1, 1) 0ms, visibility 0ms linear 0ms, background-color 0ms linear 500ms;
		border-top: 1px solid #ddd
	}
	.header-right #main-menu li {
		width: 100%;
		padding: 0
	}
	.header-right #main-menu li a {
		color: #fff;
		width: 100%;
		display: inline-block;
		padding: 10px 15px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.18);
	}
	.header-right #main-menu>li>a {
		background-color: rgba(0, 0, 0, 0.3);
		padding: 12px 15px;
		/*border-bottom: 1px solid rgba(255, 255, 255, 0.18);*/
	}
	.header-right #main-menu>li>a.cus_btn {
	    border-radius: 0;
	    text-align: left;
	    font-weight: normal;
	    background: #ef4339;
	}
	.header-right ul li .menuDropdown .sub-menu .sub-menu-child.subchild-show{
		padding: 0 10px;
	}
	.header-right #main-menu li a.dropiMenu {
		display: none
	}
	.header-right ul#main-menu li a:before {
		content: none
	}
	.header-right ul li .dropiMenu.active~.menuDropdown {
		background-color: transparent;
		max-height: 100%;
		transition: all .4s
	}
	.menubackdrop.show {
		display: none
	}
	.header-right ul li .menuDropdown {
		position: relative;
		top: 0!important;
		overflow: hidden;
		max-height: 100%;
		transform: translate(0, 0);
		z-index: initial;
		transition: all .4s;
		background-color: transparent;
		visibility: visible;
		font-size: 15px;
		padding-top: 0
	}
	.main-header.sticky .header-right ul li .menuDropdown {
		padding-top: 0
	}
	.header-right ul li .menuDropdown .menu-heading {
		background-color: rgba(0, 0, 0, 0.3);
		padding: 10px 15px;
		margin-bottom: 0
	}
	.header-right ul li .menuDropdown .menu-heading div {
		display: none
	}
	.header-right ul li .menuDropdown .menu-block {
		border-right: 0;
		transform: none!important;
		opacity: 1;
		padding: 0;
		margin: 0
	}
	.header-right ul li .menuDropdown .sub-menu {
		padding: 10px 0 0;
		background-color: #141417;
		margin-top: 0
	}
	.header-right ul li .menuDropdown .more-link a {
		color: #fff
	}
	.more-link .arrow-icon:before,
	.more-link .arrow-icon:after {
		background-color: #fff
	}
	.arrow-icon {
		right: 15px
	}
	.arrow-icon:before,
	.arrow-icon:after {
		background-color: #fff
	}
	.toogleLink.active {
		color: #fff!important
	}
	.toogleLink.active .arrow-icon:before,
	.toogleLink.active .arrow-icon:after,
	.dropiMenu.active .arrow-icon:before,
	.dropiMenu.active .arrow-icon:after {
		background-color: #fff
	}
	.header-right ul li .menuDropdown .sub-menu .sub-menu-child {
		transform: none
	}
	.header-right ul li .menuDropdown .sub-menu li a {
		padding: 12px 15px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.18)
	}
	.header-right ul li a.cd-nav-trigger {
		width: 30px;
		height: 35px;
		padding: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: flex-end
	}
	.header-right ul li a.cd-nav-trigger:before {
		content: none
	}
	.header-right ul li a.cd-nav-trigger .cd-nav-icon {
		width: 25px;
		height: 3px;
		border-radius: 10px;
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
		transform: none;
		left: auto;
		right: 0;
		margin-top: -2px;
		background-color: #333;
		position: relative
	}
	.header-right ul li a.cd-nav-trigger .cd-nav-icon:before {
		content: '';
		position: absolute;
		top: -10px;
		width: 30px;
		right: 0;
		background-color: #333;
		height: 3px;
		border-radius: 10px;
		transition: all .3s
	}
	.header-right ul li a.cd-nav-trigger .cd-nav-icon:after {
		content: '';
		position: absolute;
		bottom: -10px;
		width: 30px;
		right: 0;
		background-color: #333;
		height: 3px;
		border-radius: 10px;
		transition: all .3s
	}
	body:not(.responsive-menu--opened) .sticky-1 .header-right ul li a.cd-nav-trigger .cd-nav-icon, body:not(.responsive-menu--opened) .sticky-1 .header-right ul li a.cd-nav-trigger .cd-nav-icon:before,
	body:not(.responsive-menu--opened) .sticky-1 .header-right ul li a.cd-nav-trigger .cd-nav-icon:after{
		background-color: #fff;
	}
	.responsive-menu--opened .main-header.sticky-1 .brand-name img {
	    -webkit-filter: brightness(0);
	    filter: brightness(0);
	}
	.header-right ul li a.cd-nav-trigger.active .cd-nav-icon {
		background-color: rgba(0, 0, 0, 0)
	}
	.header-right ul li a.cd-nav-trigger.active .cd-nav-icon:before {
		transform: rotate(135deg);
		top: 0
	}
	.header-right ul li a.cd-nav-trigger.active .cd-nav-icon:after {
		transform: rotate(-135deg);
		bottom: 0
	}
	.contact-info button {
		width: 34px;
		height: 34px;
		line-height: 34px
	}
	body:not(.responsive-menu--opened) .main-header:not(.sticky):not(.menu--opend) .header-right ul li a.cd-nav-trigger .cd-nav-icon {
		background-color: #fff
	}
	body:not(.responsive-menu--opened) .main-header:not(.sticky):not(.menu--opend) .header-right ul li a.cd-nav-trigger .cd-nav-icon:before,
	body:not(.responsive-menu--opened) .main-header:not(.sticky):not(.menu--opend) .header-right ul li a.cd-nav-trigger .cd-nav-icon:after {
		background-color: #fff
	}
	.header-right ul li .menuDropdown {
		padding: 0
	}
}


@media (min-width:768px) {
	.col-lg-2-5 {
		flex: 0 0 33.33%;
		max-width: 33.33%
	}
	.col-md-full {
		flex: 0 0 100%;
		max-width: 334px;
		margin: auto
	}
}

@media (min-width:992px) {
	.col-lg-2-5 {
		flex: 0 0 20%;
		max-width: 20%
	}
}

@media(max-width:1199px) {
	.col-lg-2-5.col-md-12.menu-block {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

@media(max-width:767px) {
	.header-right ul li .menuDropdown .menu-block-5 {
		display: none
	}
}













