.banner-b-50 {
  margin-bottom: 50px;
}

@media (max-width: 1000px) {
  body{
    font-size: 12px;
  }
}

@media (min-width: 1700px) {
  .getInTouch-section:after {
    width: 30%;
  }
}
@media (min-width: 2000px) {
  .getInTouch-section:after {
    width: 40%;
  }
}

@media (max-width: 1700px) {
  .getInTouch-section:after {
    width: 680px;
  }
}
@media (max-width: 1680.98px) {
  .getInTouch-section:after {
    width: 280px;
  }

  .video-list-section {
    margin-bottom: -245px;
  }
}

@media (max-width: 1600.98px) {
  .serviceCat-slider {
    width: 89em;
  }
}
@media (max-width: 1440.98px) {
  .hiw-list {
    margin-top: 50px;
  }
  .hb-caption {
    margin: 35px 70px 0 0;
  }
  .hb-caption h1 {
    line-height: 64px;
    font-size: 50px;
  }
  .hiw-title h3 {
    font-size: 22px;
  }
  .hiw-inner-content {
    padding: 20px 32px 30px;
  }
  .hiw-inner-content p {
    font-size: 17px;
  }
  .getInTouch-section:after {
    width: 370px;
  }
  .footer-link ul li {
    margin-right: 20px;
  }
  .footer-br ul li {
    margin-right: 15px;
  }
  .footer-br ul li a,
  .footer-link ul li a,
  .footer-email a,
  .footer-bl span {
    font-size: 15px;
  }

  .video-list-section {
    margin-bottom: -233px;
  }

  .inner-banner-section:after {
    border-right: 1600px solid transparent;
  }
  .service-filter-section {
    padding: 20px 0 50px;
  }
  .db-menu-block ul li a h4 {
    font-size: 19px;
  }

  .ardCatD-head .left h2 {
    font-size: 34px;
  }

  .ardCatD-head .left span {
    font-size: 20px;
  }

  .ardCatD-head {
    padding: 20px 30px;
  }

  .ardCatD-inner-body {
    padding: 25px 30px;
  }

  .showTransaction-right h5 {
    font-size: 30px;
  }

  .showTransaction-left .balAmount-text {
    font-size: 20px;
  }

  .showTransaction-amount {
    padding: 20px 30px;
  }

  .transaction-filter {
    padding: 10px 20px;
  }

  .messenger-list ul li {
    padding: 18px 15px;
  }

  .messenger-list ul li .user-img {
    margin-right: 15px;
  }

  .messenger-list ul li .user-img img {
    width: 70px;
    height: 70px;
  }

  .detail-left .user-name {
    font-size: 22px;
  }

  .detail-left .last-message-date {
    font-size: 13px;
  }

  .detail-right .last-message {
    font-size: 18px;
  }

  .detail-left {
    margin-bottom: 5px;
  }

  .detail-right .unread-count {
    font-size: 15px;
    min-width: 36px;
    padding: 4px 0;
  }

  .messenger-list ul li + li {
    margin-top: 12px;
  }

  .chat-head {
    padding: 20px 25px;
  }

  .chatUser-left-head .chatUser-img img {
    width: 70px;
    height: 70px;
  }

  .chatHead-user-details h5 {
    font-size: 22px;
  }

  .chatUser-right-head span {
    font-size: 17px;
  }

  .chat-date-time {
    font-size: 17px;
    margin: 8px 0;
  }

  .messanger-message .chat-block {
    padding: 10px 0;
  }

  .chat-block .recived-block,
  .chat-block .sent-block {
    padding: 12px 25px;
  }

  .spdh-user-img img {
    min-width: 300px;
    height: 300px;
  }

  .spdh-user-img {
    margin: 0 50px -80px 0;
  }

  .spdh-status .spdh-status-item {
    padding: 14px 5px;
    font-size: 20px;
  }

  .spdh-user-name h1 {
    font-size: 36px;
  }

  .spd-profileInf-section {
    padding: 130px 0 70px;
  }

  .spd-profileInf-section .section-title h2,
  .review-rating-section .section-title h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .review-rating-section.pt-180 {
    padding-top: 130px;
  }

  .resetPassword-box .auth-right-blue img,
  .otp-box .auth-right-blue img,
  .createNewPass-box .auth-right-blue img {
    width: 560px;
  }

  .np-icon span {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }

  .notifyPage-item a {
    padding: 18px 15px;
  }

  .np-content p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .np-time {
    font-size: 14px;
  }
  .se-line img {
    width: 583px;
  }
  .transaction-filter-section {
    top: 90px;
  }
  .transaction-filter-section .cof-close-block {
    left: 300px;
  }
  .scale_cus_modal .scale_modal_body.scale_modal_lg {
    max-width: 540px;
  }
  .scale_cus_modal .scale_modal_body {
    max-width: 440px;
  }
}
@media (max-width: 1366.98px) {
  .scm_header .scm_head-title {
    font-size: 28px;
  }
  .confirmCancel p {
    font-size: 20px;
    padding: 0px 30px 20px;
  }
  .dotsDropdown-menu .dropdown-menu a {
    font-size: 16px;
    padding: 12px 15px;
  }
  .btn {
    font-size: 16px;
  }
  .btn-sm,
  .btn-md,
  .ardCatD-it-action a {
    font-size: 16px;
    /* padding: 12px 5px; */
  }
  .ardCat-submitQ-btn {
    width: 200px;
  }
  .home-banner-section {
    padding: 42px 0 120px;
  }
  .circle-box:after {
    top: -350px;
    left: -290px;
  }
  .ha-content-box {
    padding-left: 50px;
  }
  .hiw-item {
    width: calc(33.33% - 44px);
    margin-right: 65px;
  }
  .footer-app-link a:first-child {
    margin-right: 20px;
  }
  .footer-br ul li a,
  .footer-link ul li a,
  .footer-email a,
  .footer-bl span {
    font-size: 14.5px;
  }

  .video-block .video-img img {
    height: 500px;
  }
  #return-to-top {
    height: 35px;
    line-height: 40px;
    font-size: 15px;
    right: 15px;
    width: 40px;
  }
  .gray-hero-section h1 {
    font-size: 48px;
  }

  .db-menu-block .dbm-icon img {
    width: 80px;
  }

  .db-menu-block ul li a {
    min-height: 170px;
  }

  .db-menu-block ul li a h4 {
    font-size: 18px;
  }

  .form-group label {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .group-title h4 {
    font-size: 17px;
    padding-bottom: 8px;
  }
  .form-control,
  select.form-control,
  input.form-control,
  .selectAdd-input {
    height: 50px;
    font-size: 15px;
  }
  .form-control {
    padding: 6px 12px;
  }
  select.form-control {
    padding: 6px 38px 6px 12px;
  }
  .form-group .placeholder {
    left: 12px;
  }
  .form-group .placeholder label {
    font-size: 15px;
  }
  .selectAdd-input.cus-select-box {
    line-height: 30px;
  }
  .selectAdd-option-group .sao strong,
  .selectAdd-option-group .sao span {
    font-size: 16px;
  }
  ::placeholder {
    font-size: 16px;
  }
  :-ms-input-placeholder {
    font-size: 16px;
  }
  ::-ms-input-placeholder {
    font-size: 16px;
  }
  .ac-check-group .custom-check-block .custom-check-label {
    font-size: 16px;
  }
  .accPro-figure img {
    width: 130px;
    height: 130px;
  }
  .accPro-cal-item span,
  .accPro-cal-item h5,
  .servicecat-item span,
  .catD-lt-item .it-content span,
  .ardCatD-content h3,
  .ardCatD-content p,
  .catPoint-item span,
  .ardCatD-media-block h3 {
    font-size: 17px;
  }
  .bl-separator {
    min-width: 120px;
  }
  .accPro-cal-total span,
  .accPro-cal-total h5 {
    font-size: 20px;
  }
  .catD-lt-item .icon img {
    width: 38px;
  }
  .catD-lt-item .icon {
    margin-right: 10px;
  }
  .notify-drop-head {
    padding: 15px 15px 10px;
  }
  .notify-drop-head h4,
  .notify-drop-head .notify-markRead-btn {
    font-size: 16px;
  }
  .ni-content p,
  .ardComment-item .ardc-bottom span {
    font-size: 16px;
  }
  .ni-content .ni-time {
    font-size: 14px;
  }
  .notify-item {
    padding: 10px 15px 15px 15px;
  }

  .mfiL-content h3 {
    font-size: 22px;
  }

  .mfiL-content .rating i {
    font-size: 14px;
  }

  .mfi-bottom strong,
  .mfi-des p {
    font-size: 16px;
  }

  .mfi-bottom strong {
    margin: 5px 0 8px 0;
  }

  .mfiRight span {
    font-size: 18px;
  }

  .myFeedback-list {
    padding: 0 25px;
  }

  .mfiLeft .mfiL-img img {
    width: 60px;
    height: 60px;
  }

  .mfi-bottom {
    margin-left: 80px;
  }

  .myFeedback-item {
    padding: 25px 0;
  }

  .mfi-bottom a {
    font-size: 15px;
    margin-top: 15px;
  }
  .grayHero-tabnav .nav-tabs li a {
    width: 200px;
    font-size: 16px;
    padding: 12px 5px;
  }

  .scb-bottom span {
    font-size: 16px;
  }

  .transaction-bottom-block .price-box {
    font-size: 20px;
  }

  .scb-bottom span.f-24 {
    font-size: 22px;
  }

  .open-request .sc-name:after,
  .open-quote .sc-head:after {
    font-size: 16px;
  }
  .servicecat-item {
    padding: 20px 15px;
  }
  .rdc-title {
    font-size: 20px;
  }

  .spdh-status .spdh-status-item {
    font-size: 17px;
    padding: 12px 5px;
  }

  .spdh-user-name h1 {
    font-size: 32px;
  }

  .spdh-location {
    font-size: 18px;
  }

  .spdh-star {
    font-size: 16px;
  }

  span.spdh-count {
    font-size: 18px;
  }

  .spdh-user-img img {
    min-width: 240px;
    height: 240px;
    width: 240px;
  }

  .spdh-user-img {
    margin: 0 50px -50px 0;
  }

  .spd-profileInf-section {
    padding: 90px 0 60px;
  }
  .review-rating-section.pt-180 {
    padding-top: 90px;
  }
  .spd-profileInf-section .section-title h2,
  .review-rating-section .section-title h2 {
    font-size: 26px;
    margin-bottom: 0;
  }

  .spdp-info-list {
    padding: 30px 20px;
  }

  .spdp-flex.spdp-top h3,
  .spdp-flex.spdp-top .spdp-year {
    font-size: 24px;
  }

  .spdp-flex.spdp-top h3,
  .spdp-flex.spdp-top .spdp-year {
    font-size: 20px;
  }

  .spdp-flex.spdp-center {
    margin: 10px 0 15px;
  }

  .spdp-flex.spdp-center span {
    font-size: 18px;
  }

  .spdp-flex.spdp-bottom span {
    font-size: 16px;
    width: 210px;
  }

  .spdp-info-item + .spdp-info-item {
    margin-top: 40px;
  }

  .rating-body .rating-item {
    padding: 20px;
  }

  .ratingCover img {
    width: 60px;
    height: 60px;
  }

  .ratingCover {
    margin-right: 15px;
  }

  .rating-name-rate h4 {
    font-size: 21px;
  }

  .rating-name-rate .rating-star i {
    font-size: 13px;
  }

  .rating-name-rate .rating-star span {
    font-size: 18px;
  }

  .review-content-block p {
    font-size: 17px;
  }

  .review-content-block h5 {
    font-size: 18px;
  }

  .rating-head {
    padding: 30px;
  }

  .ratingHead-left .totalRate {
    font-size: 60px;
    line-height: 74px;
  }

  .tutorRating-block span {
    font-size: 20px;
  }
  .m_header h2 {
    font-size: 32px;
  }
  .m_header {
    margin-bottom: 10px;
  }
  .radio-block .radio-label-control,
  .addNew-bank-alc .btn {
    font-size: 16px;
  }

  .m_header p,
  .reg-tabNav .step-item,
  .auth-accordion .card-head h3 {
    font-size: 18px;
  }
  .auth-success-section .success-content h1 {
    font-size: 36px;
  }
  .radio-label-control:before {
    width: 18px;
    height: 18px;
  }

  .radio-label-control:after {
    width: 10px;
    height: 10px;
    left: 4px;
  }
  .auth-success-section .success-content p {
    font-size: 20px;
  }

  .success-inner .success-content .btn {
    font-size: 17px;
    padding: 10px 5px;
  }

  .auth-success-section {
    padding-top: 30px;
  }

  .auth-success-section .success-img img {
    width: 400px;
  }

  .auth-block .forgot-link {
    margin-bottom: 20px;
  }
  .scm_footer .btn {
    width: 150px;
    padding: 10px 5px;
    font-size: 15px;
  }
  .middleLine-sep {
    margin: 20px 0 10px;
  }

  .social-login a {
    font-size: 14px;
    padding: 9px 20px;
  }

  .social-login a img {
    /* margin-right: 6px; */
    height: 19px;
  }

  .auth-login-box .auth-white-box {
    padding: 20px 30px;
  }

  .auth-login-box .bottom-info {
    margin-top: 20px;
  }

  .auth-login-box .bottom-info p {
    font-size: 15px;
  }

  .middleLine-sep span {
    font-size: 14px;
  }

  .auth-login-box .form-group {
    margin-bottom: 12px;
  }

  .auth-login-box {
    height: calc(100vh - 98px);
  }
  .resetPassword-box .auth-right-blue img,
  .otp-box .auth-right-blue img,
  .createNewPass-box .auth-right-blue img {
    width: 440px;
  }

  .e4-pagination {
    padding: 10px 30px;
  }
  .e4p-group span {
    font-size: 16px;
    margin-right: 10px;
  }
  ul.pagination li.pageNumber a {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  ul.pagination li a {
    font-size: 16px;
  }
  .faq-title h2 {
    font-size: 24px;
  }
  .faqTab-title {
    font-size: 17px;
  }
  .faq-accordion-wrap .faqCard + .faqCard {
    margin-top: 15px;
  }
  .getContent-title h2 {
    font-size: 22px;
  }

  .getContent-item p,
  .getContent-item ul li {
    font-size: 16px;
  }
  .getContent-item ul li {
    margin-top: 8px;
  }
  .getContent-item {
    margin-top: 35px;
  }
  .getContent-item p.f-24 {
    font-size: 20px;
  }
  .enhanceProfile-img img {
    width: 350px;
  }
  .eps-left h2 {
    font-size: 36px;
    margin-bottom: 25px;
  }
  .eps-left p {
    font-size: 16px;
  }
  .primary-radio-group label {
    margin-bottom: 8px;
  }
  .addnewaddress-wrap .scm_body .form-group {
    margin: 0 10px 13px;
  }
  .serviceCat-list-wrap .service-cat-item:not(.hnc-item):hover .sc-body {
    padding-bottom: 35px;
  }
  .serviceCat-list-wrap .service-cat-item .sc-body .btn {
    width: 170px;
    padding: 9px 5px;
    font-size: 14px;
  }
  .serviceCat-list-wrap .service-cat-item {
    margin-bottom: 0;
  }
  .serviceCat-list-wrap .sc-body .btn {
    transform: translate(0px, 200%);
  }
}
@media (max-width: 1280.98px) {
  .inner-banner-section:after {
    border-right: 1280px solid transparent;
  }
  .hb-caption h1 {
    line-height: 64px;
    font-size: 47px;
  }
  .footer-email {
    margin-right: 60px;
  }
  .footer-bl span,
  .footer-br ul li a {
    font-size: 13.5px;
  }
  .service-cat-item .sc-head .right h3 {
    font-size: 21px;
  }
  .service-cat-item .sc-head .left img {
    width: 70px;
    height: 70px;
  }
  .scr-location,
  .scb-bottom span,
  .scb-top .service-type,
  .scb-top .experience {
    font-size: 18px;
  }
  .service-cat-item .sc-head {
    padding: 14px 15px;
  }
  .auth-register-box .auth-white-box {
    width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .serviceCat-slider {
    margin-top: 40px;
    padding-left: 120px;
  }
  .serviceCat-slider .slick-arrow-control {
    left: 10px;
  }
  .adv-gallery-col.mt-30 {
    margin-top: 20px;
  }
  .adv-gallery-list .adv-gallery-left {
    margin-right: 15px;
  }
  .adv-gallImg-block.ml-30 {
    margin-left: 15px;
  }
  .hiw-item {
    width: calc(33.33% - 15px);
    margin-right: 22px;
  }
  .hiw-title h3 {
    min-height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ha-content-box {
    padding-left: 0;
  }
  .hb-caption {
    margin: 0;
  }
  .home-banner-section {
    padding: 90px 0 180px;
  }
  .header-right ul li a.cus_btn {
    width: 130px;
    font-size: 16px;
  }
  .header-right ul.main-menu > li > a:not(.cus_btn) {
    font-size: 16px;
  }
  .hb-caption h1 {
    line-height: 56px;
    font-size: 43px;
  }
  .hiw-item .hiw-icon,
  .aboutUs-col-list .hiw-item .hiw-icon {
    padding: 35px 10px 35px;
  }
  .hiw-item .hiw-icon img {
    height: 200px;
  }
  .ha-content-box ul li span {
    font-size: 22px;
  }
  .ha-content-box ul li img {
    margin-right: 12px;
    width: 26px;
  }
  .people-voice-slider {
    padding: 0 30px 0 70px;
    margin-top: 70px;
  }
  .slick-arrow-control {
    height: 50px;
    width: 50px;
  }
  .people-voice-slider .slick-arrow-control.prev-control,
  .people-voice-slider .slick-arrow-control.next-control {
    top: 110px;
  }
  .voice-item {
    padding: 10px 40px 10px 10px;
  }
  .voice-item .voice-img img {
    height: 260px;
  }
  .ourFeature-img img {
    display: block;
    margin: 40px auto 0;
  }
  .footer-bottom {
    flex-wrap: wrap;
  }
  .footer-br ul {
    justify-content: space-between;
  }
  .footer-br ul li a,
  .footer-bl span {
    font-size: 13.5px;
  }
  .footer-bl,
  .footer-br {
    width: 100%;
  }
  .footer-bl {
    text-align: center;
    margin-top: 15px;
  }
  .footer-app-link {
    text-align: center;
    margin-bottom: 40px;
  }

  .inner-banner-section {
    padding: 100px 0 100px;
  }
  .inner-banner-section:after {
    border-bottom: 118px solid white;
  }
  .video-block .video-img img {
    height: 400px;
  }
  .inner-caption h1 {
    line-height: 56px;
    font-size: 50px;
  }
  .inner-caption p {
    font-size: 20px;
  }

  .workProcess-row:nth-child(odd) .work-process-content {
    padding-left: 30px;
  }
  .workProcess-row:nth-child(even) .work-process-content {
    padding-right: 30px;
  }
  .video-list-section {
    margin-bottom: -187px;
    background-size: contain;
    padding-top: 40px;
    padding-bottom: 250px;
  }

  .service-filter-section {
    padding: 20px 0 25px;
  }
  .service-filter-section .form-group {
    margin-bottom: 24px;
  }
  .service-cat-item {
    width: calc(50% - 25px);
    margin-right: 50px !important;
  }
  .service-cat-item:nth-child(even) {
    margin-right: 0 !important;
  }
  .service-filter-section .wp-clip-bg {
    top: -70px;
  }

  .db-menu-block .dbm-icon img {
    width: 64px;
  }

  .db-menu-block ul li a h4 {
    font-size: 14px;
  }

  .db-menu-block ul li a {
    min-height: 140px;
  }

  .db-top-menu {
    margin-top: -80px;
    padding-bottom: 50px;
  }

  .gray-hero-section.db-gh-section {
    padding: 60px 0 100px;
  }
  .ac-pro-box {
    margin-bottom: 40px;
  }
  .accPro-cal-item {
    justify-content: space-between;
  }

  .accPro-cal-item h5 {
    width: auto;
  }
  .acs-left-block {
    margin-right: 0;
  }
  .cmr-form-block,
  .blankService-tab-go {
    padding: 30px 20px 20px;
    margin-bottom: 60px;
  }
  .cpp-white-box {
    padding: 30px 20px 20px;
  }

  .cptg-item .cptg-title {
    font-size: 16px;
  }

  .feedback-detail-head h2 {
    font-size: 40px;
  }

  .feedback-detail-head p {
    font-size: 20px;
  }

  .feedback-detail-head {
    margin-bottom: 30px;
  }
  .status-tabnav ul li a {
    font-size: 18px;
  }

  .status-tabnav ul li {
    margin-right: 30px;
  }

  .cpp-white-box .status-tabnav ul li a.active:after {
    bottom: -12px;
    width: 30px;
  }
  .pdb-body {
    padding: 20px 20px 30px;
  }
  .oneReq-section .ardCat-list {
    margin-bottom: 40px;
  }
  .ardCatD-head .right .ardCatuser-d h3 {
    font-size: 22px;
  }
  .ardCat-tab-wrap:not(.ardCat-show) .ardCat-content-wrap,
  .chatMain-tab-wrap:not(.chatbox-show) .messanger-msg-col {
    display: none;
  }

  .ardCat-show .ardCat-col-box,
  .chatbox-show .messenger-tab-col {
    display: none;
  }
  .ardCat-tab-wrap .custom-scroll {
    height: auto;
  }
  .ardCatD-head {
    flex-wrap: wrap;
    padding: 20px;
  }
  .ardCat-back-block {
    margin-bottom: 10px;
    width: 100%;
  }

  .ardCat-back-block .ardCat-back-btn {
    background: transparent;
    border: none;
    padding: 0;
    font-size: 30px;
    line-height: 1;
  }
  .messenger-list ul {
    height: auto;
  }
  .chatHead-user-details h5 {
    white-space: nowrap;
  }
  .chatHead-user-details h5 {
    white-space: nowrap;
  }

  .chatUser-left-head .ardCat-back-block {
    margin-bottom: 0;
    margin-right: 15px;
  }
  .messenger-list .mCSB_inside > .mCSB_container {
    margin-right: 0;
  }
  .ardCat-widget a {
    width: 60px;
    height: 60px;
  }

  .ardCat-widget a img {
    width: 20px;
  }

  .ardCat-widget a span {
    font-size: 9px;
  }

  .ardCat-widget {
    right: 20px;
  }

  .ardCatD-right-space {
    margin-right: 60px;
  }

  .status-tabnav {
    width: 100%;
  }

  .request-fs-block {
    flex-wrap: wrap;
  }

  .requestFilter-block {
    width: 100%;
    justify-content: space-between;
  }

  .status-tabnav .nav-tabs {
    justify-content: center;
    margin-bottom: 30px;
  }

  .status-tabnav ul li a:after {
    bottom: -10px;
  }
  .dotsDropdown-menu .dropdown-menu a {
    font-size: 15px;
    padding: 12px 15px;
  }

  .message-typing {
    height: 75px;
  }

  .message-typing .send-btn {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }

  .message-typing .send-btn img {
    width: 22px;
  }

  .messanger-message .chat-block {
    height: calc(100vh - 260px);
  }

  .messenger-list ul li .user-img img {
    width: 55px;
    height: 55px;
  }

  .messenger-list ul li {
    padding: 15px 12px;
  }

  .messenger-list ul li .user-img {
    margin-right: 10px;
  }

  .detail-left .user-name {
    font-size: 20px;
  }

  .detail-left .last-message-date {
    font-size: 11px;
  }

  .detail-right .last-message {
    font-size: 15px;
  }

  .detail-right .unread-count {
    font-size: 14px;
    min-width: 30px;
    padding: 3px 0;
  }

  .chat-head {
    padding: 20px;
  }

  .chatUser-right-head span {
    font-size: 16px;
  }

  .chat-block .recived-block,
  .chat-block .sent-block {
    padding: 12px 20px;
  }

  .chat-date-time {
    font-size: 15px;
    margin: 5px 0;
  }
  .peopleVoice-item.video-block .video-img img {
    height: 260px;
  }

  .auth-marquee-tag,
  .resetPassword-box .auth-right-blue,
  .otp-box .auth-right-blue,
  .createNewPass-box .auth-right-blue {
    display: none;
  }

  .auth-login-box {
    background: transparent;
  }

  .auth-login-box .auth-white-box {
    margin: auto;
  }
  .getContent-img {
    padding: 60px 0;
  }
  .getContent-item p + p {
    margin-top: 15px;
  }
  .np-content {
    width: 100%;
  }
  .notifyPage-list {
    margin-bottom: 50px;
  }
  .npr-bottom h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .npr-bottom p {
    font-size: 18px;
  }

  .npr-icon img {
    width: 110px;
  }

  .npr-bottom {
    margin-top: 30px;
  }

  .npr-bottom .btn {
    font-size: 16px;
    padding: 9px 30px;
  }
  .se-line img {
    width: 443px;
  }
  .startEaring-content {
    width: auto;
  }

  .startEaring-row:nth-child(odd) .startEaring-content {
    padding-left: 0;
  }

  .startEaring-content .title h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .video-list-section.aboutVideoLIst-section {
    padding-top: 60px;
    margin-bottom: -267px;
    padding-bottom: 270px;
  }
  .enhanceProfile-inner {
    padding: 0 50px 0 60px;
  }
  .transaction-filter-section .cof-close-block {
    left: auto;
    right: 40px;
  }
  .header-right ul li .menuDropdown .menu-heading {
    text-align: left;
    position: relative;
  }
  .header-right ul li .menuDropdown .menu-heading:after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .header-right ul li .menuDropdown .menu-heading.closed:after {
    transform: translateY(-50%) rotate(90deg);
  }
  .header-right ul li .menuDropdown .sub-menu {
    display: none;
  }
  .header-right ul li .menuDropdown .sub-menu.subMenu-open {
    display: flex;
  }

  /*.header-right ul li .menuDropdown .menu-heading {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }*/
  .serviceCat-slider {
    width: 62em;
  }
}

@media (max-width: 991.98px) {
  .hb-content-img img {
    display: block;
    margin: auto;
  }
  .circle-box:after {
    top: -420px;
    left: -310px;
  }
  .circle-box:before {
    top: -238px;
    right: -210px;
  }
  .serviceCat-slider {
    padding-left: 80px;
  }
  .sc-item {
    margin-right: 20px;
  }
  .adv-gallery-list {
    flex-wrap: wrap;
  }
  .adv-gallery-list .adv-gallery-left, .adv-gallery-right {
    width: 100%;
    margin: 0 0 20px 0;
  }

  
  /*.happening-slider.moving-left{
		animation: slide 100s linear infinite;
	}
	.happening-slider.moving-right{
		animation: slideReverse 100s linear infinite;
	}*/
  .happening-section .section-des {
    margin: 0;
  }
  .people-voice-section:before,
  .home-happening-list:before {
    border-bottom: 150px solid white;
    /* content: none; */
  }
  .people-voice-section:after,
  .home-happening-list:after {
    border-top: 150px solid white;
    /* content: none; */
  }
  .hiw-item {
    width: calc(50% - 13px);
    margin-right: 25px;
  }
  .hiw-item:last-child {
    margin-top: 30px;
  }
  .hiw-item:nth-child(2) {
    margin-right: 0;
  }
  .people-voice-section {
    padding: 80px 0;
  }
  .footer-br ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-br ul li {
    margin-right: 22px;
  }
  .section-des p {
    font-size: 20px;
  }
  .voice-item {
    padding: 10px;
  }
  .people-voice-slider {
    padding: 0;
  }

  .au-content-img {
    margin-top: 60px;
  }

  .inner-banner-section:after {
    border-bottom: 88px solid white;
    border-right: 770px solid transparent;
  }

  .work-process-img {
    margin-top: 40px;
  }

  .service-cat-item {
    width: 100%;
    margin: 0 !important;
  }
  .service-categories-section.pad-t-100 {
    padding: 50px 0 20px;
  }
  .db-top-menu {
    display: none;
  }
  .db-top-menu {
    display: none;
  }

  .gray-hero-section.db-gh-section {
    padding: 40px 0;
    margin-bottom: 50px;
  }

  .gray-hero-section h1 {
    font-size: 40px;
  }
  .group-md-6 {
    width: calc(50% - 20px);
  }
  .group-md-12 {
    width: 100%;
  }
  .catD-media-list {
    flex-wrap: wrap;
  }

  .catD-media-list .catDM-item {
    margin: 0 10px 10px 0;
  }

  .profileUp-inner .upload-here {
    width: 120px;
  }
  .acs-left-block {
    padding: 30px 25px;
  }
  .pdb-body {
    padding: 20px 20px 25px;
  }
  .cpp-white-box {
    padding: 30px 20px;
  }
  .status-tabnav ul li a,
  .profileUp-inner .upload-content h5 {
    font-size: 16px;
  }
  .addNewAddress-request .btn {
    font-size: 15px;
  }
  .profileUp-inner .upload-content {
    margin-left: 8px;
  }

  .ardCat-list.transaction-scroll-box {
    height: 400px;
  }

  .transaction-filter .moreFilter-btn,
  .searchAction-block .search-filter-btn,
  .requestFilter-block .moreFilter-btn {
    width: 46px;
    height: 46px;
  }

  .transaction-filter {
    margin-right: 0;
  }

  .ardCat-list .mCSB_inside > .mCSB_container {
    margin-right: 0;
  }

  .transaction-invoice-book .btn {
    font-size: 16px;
    padding: 12px 20px;
  }

  .ardCatD-inner-body {
    padding: 25px 20px;
  }

  .ardCatD-head .left h2 {
    font-size: 30px;
  }

  .ardCatD-head .left span,
  .rvq-block .red-text-btn {
    font-size: 16px;
  }

  .mc-rate-sp-group .ac-check-group {
    width: calc(50% - 10px);
    margin-bottom: 10px !important;
  }

  .mc-rate-sp-group .ac-check-group:nth-child(2) {
    margin-right: 0;
  }
  .trackService-proovider-block {
    margin-top: 40px;
  }
  .ardCatD-head .right .ardCatuser-d h3 {
    font-size: 20px;
  }
  .ardCatD-head .right .img img {
    width: 70px;
    height: 70px;
  }
  .ardCatD-head .rating .rating-count {
    font-size: 17px;
  }
  .hnc-item.service-cat-item {
    margin-bottom: 20px !important;
  }
  .scm_header .scm_head-title {
    font-size: 36px;
  }
  .scm_footer .btn {
    font-size: 17px;
  }

  .spdh-user-img {
    margin: 0 50px 0 0;
  }

  .spd-hero-section {
    padding-top: 40px;
  }

  .spd-profileInf-section {
    padding: 50px 0 30px;
  }

  .rating-bars .rating-stroke-line {
    width: 160px;
  }
  .review-rating-section.pt-180 {
    padding-top: 50px;
  }
  ul.pagination li.pageNumber a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  ul.pagination li a,
  .e4p-group span {
    font-size: 14px;
  }
  .e4-pagination {
    padding: 8px 15px;
  }
  .faq-title h2 {
    font-size: 20px;
  }
  .getContent-section.contactUs-section {
    padding-bottom: 0;
  }
  .contactUs-form {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .contactUs-form {
    padding: 35px 20px;
  }

  .emailUs-icon img {
    width: 90px;
  }

  .emailUs-block h3 {
    font-size: 34px;
  }

  .emailUs-block h3 ~ a {
    font-size: 20px;
  }
  .se-line img {
    display: none;
  }

  .startEaring-row {
    padding-bottom: 60px;
  }

  .startEaring-img img {
    width: 500px;
    margin: auto;
    display: block;
  }

  .enhanceProfile-section {
    padding: 50px 0 20px;
  }

  .enhanceProfile-inner {
    padding: 60px 40px 0;
    flex-wrap: wrap;
  }

  .eps-left,
  .eps-right {
    width: 100%;
  }

  .eps-right {
    text-align: center;
  }
  .video-list-section.aboutVideoLIst-section {
    margin: 0;
    padding-bottom: 0;
  }
  .eps-left {
    margin-right: 0;
  }
  .transaction-filter-section {
    top: 70px;
  }
  .transaction-filter-section .cof-close-block {
    right: 20px;
  }
  .rating-body.custom-scroll {
    height: auto;
  }
  .bell-btn .dropdown-menu .dropdown-menu-inner .notify-list {
    overflow-y: auto;
  }
  .home-about-section {
    padding-bottom: 60px;
  }
  .serviceCat-slider {
    width: calc(100% + 15px);
  }
}
@media (max-width: 767.98px) {
  .hb-down-arrow {
    display: none;
  }
  .circle-box:after {
    top: -350px;
    left: -470px;
    border: 50px solid #3b3b3b;
    height: 600px;
    width: 600px;
  }
  .circle-box:before {
    top: -218px;
    right: -260px;
  }
  .hb-content-img {
    margin: 60px 0 20px;
  }
  .hb-caption h1 {
    line-height: 50px;
    font-size: 25px;
    margin-bottom: 15px;
  }
  .hb-search-form .form-group {
    /* flex-wrap: wrap; */
    align-items: initial;
    width: 100%;
  }
  .hb-search-form {
    display: block;
    margin-bottom: 10px;
  }
  .hb-search-form .form-group .form-group-inner {
    width: 100%;
  }
  .hb-search-form .form-group .form-group-inner:first-child {
    width: 100%;
    border-bottom: 1px solid #ccc;
  }

  /* .hb-search-form .form-control {
    height: 58px;
  } */

  .input-control-icon {
    line-height: 58px;
  }

  .hb-search-form .btn {
    width: 100%;
    padding: 9px 5px;
    font-size: 16px;
  }
  .section-title h2 {
    font-size: 32px;
    line-height: 42px;
  }
  .section-des p {
    font-size: 16px;
  }
  .serviceCat-slider {
    padding-left: 0;
  }
  .hiw-item,
  .ourFeature-item {
    width: 100%;
    margin-right: 0 !important;
  }
  .hiw-item:nth-child(2) {
    margin-top: 30px;
  }
  .hiw-title h3 {
    min-height: initial;
  }
  .ha-content-img {
    margin-top: 30px;
  }
  .social-list a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
  }
  footer:after {
    content: none;
  }
  .footer-circle-box:after {
    height: 360px;
    width: 360px;
    top: -30%;
    left: -50%;
    border: 60px solid #3b3b3b;
  }
  .getInTouch-section .container {
    padding: 0;
  }
  section.getInTouch-section {
    padding-top: 0;
    background: #ff8805;
  }
  .getInTouch-box {
    position: initial;
    margin: 0;
    min-height: initial;
    height: initial;
    border-radius: 0;
    width: 100%;
    padding: 20px 0;
  }
  .getInTouch-section:after {
    content: none;
  }
  .footer-top-row {
    padding: 50px 0 0;
  }
  .footer-bottom {
    border-top: 0;
    padding: 0;
  }
  .footer-br ul {
    justify-content: flex-start;
  }
  .footer-br ul li {
    width: 100%;
  }
  .footer-bl {
    margin: 30px 0 15px;
    border-top: 1px solid rgb(255 255 255 / 0.2);
    padding-top: 20px;
  }
  .footer-app-link a img {
    width: 160px;
  }
  .footer-link-block,
  .footer-link ul {
    flex-wrap: wrap;
  }
  .footer-link ul li {
    width: 100%;
  }
  .footer-link-block .footer-link:last-child {
    margin-top: 20px;
  }
  .footer-br ul li a,
  .footer-link ul li a,
  .footer-email a,
  .footer-bl span {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .footer-app-link a:first-child {
    margin-right: 8px;
  }
  .videoModal-body .close {
    color: #fff;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    top: -40px;
    right: 15px;
  }
  .videoModal-box.scale_cus_modal .scale_modal_body {
    max-width: 100%;
    background: transparent;
  }

  .inner-banner-section:after {
    border-right: 500px solid transparent;
  }
  .inner-caption h1 {
    font-size: 40px;
  }
  .inner-caption p {
    font-size: 16px;
  }
  .au-content-box h5 {
    font-size: 20px;
  }
  .video-block .video-img img {
    height: 200px;
  }
  .au-app-link a:first-child {
    margin-right: 5px;
  }

  .au-app-link a img {
    width: 160px;
  }
  .video-list-section {
    background-position: top 10% center;
  }

  .workProcess-row:nth-child(odd) .work-process-content {
    padding-left: 0px;
  }
  .workProcess-row:nth-child(even) .work-process-content {
    padding-right: 0px;
  }

  .work-process-content .des-list ul li span {
    font-size: 16px;
  }

  .work-process-content .des-list ul li img {
    width: 16px;
    margin: 4px 15px 0 0;
  }
  .work-process-content .title h2 {
    font-size: 25px;
  }
  .video-list-section {
    margin: 0;
    padding-bottom: 0;
  }

  .service-cat-item .sc-head .right {
    margin-left: 12px;
  }
  .sc-head .rating i {
    font-size: 12px;
  }
  .sc-head .rating-count {
    font-size: 16px;
  }
  .sc-body {
    padding: 15px;
  }
  .service-cat-item .sc-head .right h3 {
    line-height: initial;
  }
  .scr-location,
  .scb-bottom span,
  .scb-top .service-type,
  .scb-top .experience {
    font-size: 16px;
  }

  .user-name {
    /* display: none; */
    margin-left: 5px;
  }

  .user-btn .userBtn-icon img {
    margin-right: 0;
    width: 36px;
    height: 36px;
  }

  .user-btn {
    padding: 0;
    background: transparent;
  }

  .header-right ul li.menuToggle {
    padding-left: 4px;
  }

  .user-menu .dropdown-menu {
    right: -35px;
    width: 250px;
  }

  .user-menu .dropdown-menu:after {
    right: 40px;
  }

  .user-menu .dropdown-menu .dropdown-item img {
    width: 18px;
    height: auto;
  }

  .user-menu .dropdown-menu .dropdown-item {
    font-size: 15px;
  }

  .bell-btn .dropdown-menu {
    width: 330px;
    right: -80px;
  }

  .notify-item .ni-icon {
    display: none;
  }

  .ni-content p {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .ni-content .ni-time {
    font-size: 12px;
  }

  .notify-item {
    padding: 10px 15px;
  }

  .bell-btn .dropdown-menu:after {
    right: 87px;
  }

  .notify-drop-head {
    padding: 10px;
  }

  .notify-drop-head h4,
  .notify-drop-head .notify-markRead-btn {
    font-size: 15px;
  }

  .db-gh-section.gray-hero-section h1 {
    font-size: 36px;
  }
  .gray-hero-section.db-gh-section {
    padding: 30px 0;
    margin-bottom: 35px;
  }

  .acs-left-block {
    padding: 30px 15px;
  }

  .acsOr-group span {
    padding: 10px 0;
    font-size: 15px;
  }

  .rsp-star-block .orsp-text {
    font-size: 15px;
  }

  .rsp-star-block .star-box i {
    font-size: 17px;
    margin-right: 7px;
  }

  .rsp-star-block {
    height: 50px;
    padding: 6px 15px;
  }

  .acc-group-list .ac-check-group {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }

  .acs-left-block .form-group {
    margin-bottom: 15px;
  }

  .accPro-calculation {
    padding: 0 15px;
  }

  .accPro-cal-item span {
    min-width: auto;
  }

  .accPro-cal-item span,
  .accPro-cal-item h5,
  .servicecat-item span,
  .catD-lt-item .it-content span,
  .ardCatD-content h3,
  .ardCatD-content p,
  .catPoint-item span,
  .ardCatD-media-block h3 {
    font-size: 16px;
  }

  .bl-separator {
    display: none;
  }

  .accPro-cal-note {
    padding: 0 15px 20px;
    font-size: 14px;
  }

  .accPro-cal-total span,
  .accPro-cal-total h5 {
    font-size: 18px;
  }

  .accPro-cal-total {
    padding-top: 12px;
  }

  .accPro-cal-bottom {
    padding: 25px 15px;
  }

  .servicecat-item span.scl,
  .catD-lt-item .it-content span.itl,
  .ardCatD-content h3,
  .catPoint-item span:first-child,
  .ardCatD-media-block h3 {
    margin-bottom: 5px;
  }

  .catD-lt-item .icon img {
    width: 30px;
  }

  .catD-lt-item .icon {
    min-width: 35px;
  }
  .formSubmit-ac .btn {
    width: 200px;
  }
  .btn {
    font-size: 17px;
  }
  .btn-sm,
  .btn-md {
    padding: 10px 5px;
    font-size: 17px;
  }

  .cmr-form-block,
  .blankService-tab-go {
    padding: 30px 15px 20px;
  }

  .form-group label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .group-title h4 {
    font-size: 16px;
    padding-bottom: 6px;
  }
  .radio-block,
  .radio-label-control,
  .changeProfile-type-group {
    flex-wrap: wrap;
  }

  .radio-label-control:before {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }

  .radio-label-control:after {
    width: 10px;
    height: 10px;
    left: 4px;
  }

  .radio-block .radio-label-control {
    font-size: 16px;
  }

  .radio-block .radio-group {
    width: 100%;
    margin-top: 5px;
  }

  .radio-block .radio-group:first-child {
    margin-top: 0;
  }

  .changeProfile-type-group .cptg-item + .cptg-item {
    margin-left: 0;
    /* empty-cells: ; */
    margin-top: 7px;
  }

  .group-xs-12 {
    width: 100%;
  }

  .cmr-form-block .form-group {
    margin: 0 0 15px;
  }

  .cptg-item {
    padding: 12px 10px;
  }

  .e-switch {
    height: 22px;
    width: 40px;
  }

  .e-slider:before {
    width: 15px;
    height: 15px;
    bottom: 3.5px;
  }

  .e-switch input:checked + .e-slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }

  .catD-media-list .catDM-item img {
    width: 90px;
    height: 90px;
  }

  .catDM-video span {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }

  .catDM-item .media-delete-btn {
    font-size: 14px;
  }

  .cmr-form-block .form-submit {
    margin-top: 30px;
  }

  .cmr-form-block .form-submit .btn {
    margin: 5px !important;
  }

  .btn-sm {
    width: 120px;
  }

  .profileUp-inner .upload-here {
    width: 80px;
    min-width: 80px;
    height: 80px;
  }

  .profileUp-inner .upload-content span {
    font-size: 14px;
  }

  /* .textarea-limit {
    display: none;
  } */
  .form-control,
  select.form-control,
  input.form-control {
    padding: 5px 12px;
  }
  textarea.form-control {
    padding: 12px 12px;
  }
  .group-title h4 {
    font-size: 16px;
    padding: 10px 0;
  }
  .acs-left-block {
    padding: 30px 15px;
  }

  .feedback-detail-head h2 {
    font-size: 36px;
  }

  .feedback-detail-head p {
    font-size: 18px;
  }
  .grayHero-tabnav .nav-tabs li a {
    width: auto;
    min-width: 140px;
    padding: 10px 5px;
    font-size: 15px;
  }

  .myFeedback-list {
    padding: 0 15px;
  }

  .mfi-bottom {
    margin-left: 0;
  }

  .mfi-top {
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .mfiL-content h3 {
    font-size: 20px;
  }

  .mfiL-content .rating i {
    font-size: 13px;
  }

  .mfiL-content .rating span {
    font-size: 14px;
  }

  .mfiLeft .mfiL-img {
    margin-right: 10px;
  }

  .mfi-top .mfiLeft {
    width: 100%;
    margin-bottom: 15px;
  }

  .mfiRight span {
    font-size: 16px;
  }

  .blankService-tab-go {
    width: 100%;
  }
  .transaction-tab-content {
    padding-bottom: 60px;
  }
  .cpp-white-box {
    padding: 30px 15px;
  }

  .pdb-body {
    padding: 20px 15px 20px;
  }

  .cpp-tab-inner {
    padding-bottom: 60px;
  }

  .cpp-white-box .status-tabnav {
    margin-bottom: 20px;
  }

  .cpp-white-box .status-tabnav .nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
    overflow-y: hidden;
    justify-content: flex-start;
  }

  .status-tabnav ul li {
    margin-right: 15px;
  }

  .status-tabnav ul li a {
    font-size: 16px;
  }

  .cpp-white-box .status-tabnav ul li a.active:after {
    width: 25px;
    bottom: -6px;
    height: 2px;
  }
  .pdb-head-img {
    margin: -65px auto 0;
  }

  .camera-row label {
    width: 34px;
    height: 34px;
    line-height: 34px;
    margin: 0;
    font-size: 14px;
  }
  .pdb-head .pdb-head-img img {
    height: 110px;
    width: 110px;
  }

  .scb-bottom span.f-24 {
    font-size: 20px;
  }

  .scb-bottom span {
    font-size: 14px;
  }

  .transaction-bottom-block .price-box {
    font-size: 18px;
  }

  .open-request .sc-name:after,
  .open-quote .sc-head:after {
    font-size: 14px;
  }
  .transaction-filter span {
    font-size: 20px;
  }

  .transaction-filter span {
    font-size: 20px;
  }

  .transaction-filter .moreFilter-btn,
  .searchAction-block .search-filter-btn,
  .requestFilter-block .moreFilter-btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
  }

  .transaction-filter .moreFilter-btn img,
  .searchAction-block .search-filter-btn img,
  .requestFilter-block .moreFilter-btn img {
    width: 17px;
  }

  .showTransaction-amount {
    padding: 20px 15px;
  }

  .showTransaction-left .balAmount-text {
    font-size: 16px;
  }

  .showTransaction-right h5 {
    font-size: 24px;
  }

  .ardCatD-head {
    padding: 20px 15px;
  }

  .ardCatD-head .left h2 {
    font-size: 24px;
    margin-bottom: 6px;
  }

  .ardCatD-head .left span,
  .rvq-block .red-text-btn {
    font-size: 15px;
  }

  .ardCatD-point-list .catPoint-item {
    width: 100%;
  }

  .ardCatD-inner-body {
    padding: 20px 15px;
  }

  .transaction-bottom-block,
  .transaction-invoice-book {
    flex-wrap: wrap;
  }

  .tib-right {
    margin-top: 25px;
  }

  .transaction-invoice-book .btn {
    font-size: 15px;
    padding: 10px 20px;
  }

  .transaction-invoice-book .tib-label {
    font-size: 17px;
  }

  .tib-left .updatePDF-down img {
    width: 45px;
  }

  .tib-left .updatePDF-down {
    width: 75px;
    height: 75px;
  }
  .ardCatD-head .right .ardCatuser-d h3 {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 5px;
  }
  .rating {
    line-height: 1;
  }
  .ardCatD-head .right .img img {
    width: 60px;
    height: 60px;
  }
  .ardCatD-head .rating i {
    font-size: 13px;
  }
  .ardCatD-head .rating .rating-count {
    font-size: 16px;
  }

  .ardCatD-location-time {
    flex-wrap: wrap;
  }

  .ardCatD-it-action {
    margin-top: 30px;
    width: 100%;
    text-align: center !important;
  }

  .ardCatD-right-space {
    margin-right: 0;
  }

  .ardCat-widget {
    position: initial;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .ardCat-widget a + a {
    margin: 0 0 0 20px;
  }

  .ardCat-widget:not(.locationMap-block) a {
    width: 90px;
    height: auto;
    padding: 10px;
  }

  /* .ardCat-widget:not(.locationMap-block) a img {
    display: none;
  } */

  .ardCat-widget.locationMap-block {
    justify-content: flex-end;
  }

  .ardCat-widget a span {
    font-size: 15px;
  }
  .scm_header .scm_head-title {
    font-size: 28px;
  }
  .scale_cus_modal .scale_modal_body,
  .scale_cus_modal .scale_modal_body.scale_modal_lg {
    padding: 30px 15px;
    width: 95%;
  }

  .scm_footer .btn {
    width: 48%;
    padding: 10px 5px;
    font-size: 16px;
  }
  .ardCatD-head .right {
    margin-top: 15px;
  }
  .ardCat-submitQ-btn {
    margin: auto;
  }

  .status-tabnav ul li:last-child {
    margin-right: 0;
  }

  .status-tabnav ul li {
    margin-right: 30px;
  }

  .request-fs-block {
    padding: 20px 15px;
  }

  .search-city-form,
  .ardCatD-cat-block {
    flex-wrap: wrap;
  }

  .search-city-form .sc-form-group {
    width: 100%;
  }

  .search-city-form {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .search-city-form {
    width: calc(100% - 50px);
  }
  .cat-open-filter {
    padding: 30px 15px;
  }

  .rdc-date {
    flex-wrap: wrap;
  }

  .rdc-date .rdcd-left,
  .rdc-date .rdcd-right {
    width: 100%;
  }
  .requestDetail-hero-section {
    padding: 40px 0;
  }

  .requestDetail-hero-section .form-group {
    margin-bottom: 18px;
  }
  .detail-left .user-name {
    display: block;
  }
  .requestDetail-section {
    padding-bottom: 60px;
  }

  .chat-head {
    padding: 0;
    flex-wrap: wrap;
  }

  .chatUser-right-head {
    margin-top: 15px;
    width: 100%;
  }

  .chatUser-right-head span {
    text-align: left;
  }

  .chat-block .sent-img img {
    width: 30px;
    height: 30px;
  }

  .chat-block .recived-block,
  .chat-block .sent-block {
    padding: 12px;
  }

  .chat-block span.sent-time {
    font-size: 12px;
  }

  .chat-block .sent-message {
    padding: 12px 15px;
    font-size: 15px;
  }

  .message-typing {
    padding-right: 8px;
  }

  .spdh-inner,
  .spdh-status {
    flex-wrap: wrap;
  }

  .spdh-left {
    width: 100%;
  }

  .spdh-user-img {
    margin: auto 0 30px;
    display: block;
    text-align: center;
  }

  .spdh-right {
    text-align: center;
  }

  .spdh-star {
    justify-content: center;
  }

  .spdh-status .spdh-status-item:last-child {
    border-radius: 0;
  }

  .spdh-status .spdh-status-item:first-child {
    border-top-right-radius: 20px;
  }

  .spdp-info-list {
    padding: 25px 15px;
  }

  .spdp-flex {
    flex-wrap: wrap;
  }

  .spdp-flex.spdp-bottom span {
    width: auto;
    padding: 9px 15px;
    margin-right: 10px;
    display: inline-block;
    margin-bottom: 5px;
  }

  .spdp-flex.spdp-bottom span:last-child {
    margin-right: 0;
  }

  .rating-head {
    padding: 30px 15px;
    flex-wrap: wrap;
  }

  .rating-bars .rating-stroke-line {
    width: 140px;
  }

  .allover-rating-box {
    width: 100%;
    margin-top: 25px;
  }

  .rating-body .rating-item {
    padding: 20px 15px;
  }
  .sp-rate-section .spdh-right {
    margin: 0 0 20px;
  }

  .auth-back {
    display: none;
  }

  .m_header h2 {
    font-size: 30px;
    margin-bottom: 7px;
  }

  .m_header p {
    font-size: 17px;
  }

  .auth-register-box .auth-white-box {
    padding: 20px 15px 20px;
  }

  .auth-register-box .auth-form .form-group {
    margin: 0 0 15px;
  }

  .auth-accordion .card-head h3 {
    margin: 0;
  }

  .auth-accordion .card-head h3:after {
    font-size: 16px;
  }

  .auth-accordion .card:not(:last-child) {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .reg-tabNav .step-item {
    font-size: 14px;
  }

  .step2-now.step .step-name:after {
    top: -20px;
  }

  .step2-now.step .step-name:after,
  .step2-now.step .step-name:before {
    left: 0;
    right: 0;
    margin: auto;
    transform: none;
  }

  .step2-now.step .step-name:before {
    top: -17px;
  }

  .reg-tabNav {
    padding: 15px 0 30px;
  }

  .bottom-info p {
    font-size: 16px;
  }

  .bottom-info {
    margin-top: 25px;
  }

  .form-group .placeholder {
    left: 12px;
  }

  .auth-success-section .success-content h1 {
    font-size: 32px;
  }

  .auth-success-section .success-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .success-inner .success-content .btn {
    font-size: 15px;
    padding: 8px 5px;
  }
  .hb-search-form .input-control-icon {
    display: none;
  }

  .peopleVoice-col:nth-child(odd) {
    padding-right: 15px;
  }

  .peopleVoice-col:nth-child(even) {
    padding-left: 15px;
  }
  .peopleVoice-item.video-block {
    margin: 20px 0;
  }

  .auth-login-box .auth-white-box {
    width: auto;
    padding: 20px 15px;
  }

  .auth-login-box {
    height: auto;
    padding: 60px 0;
  }

  .social-login a {
    width: auto;
    margin-right: 5px;
    padding: 6px 10px;
    min-width: 90px;
  }

  .social-login {
    justify-content: center;
  }

  .middleLine-sep {
    margin: 25px 0 30px;
  }

  .auth-login-box .form-group {
    margin-bottom: 20px;
  }

  .social-login a img {
    height: 16px;
    margin-right: 7px;
  }

  .auth-block .forgot-link {
    margin-bottom: 30px;
  }

  .auth-login-box .bottom-info {
    margin-top: 30px;
  }

  .e4-pagination {
    flex-wrap: wrap;
  }

  .e4p-left,
  .e4p-right {
    width: 100%;
  }

  .e4p-right .e4p-group {
    margin-top: 25px;
    justify-content: center;
  }

  .e4p-group .form-control {
    width: 90px;
  }

  ul.pagination li:not(.pageNumber) .next {
    margin-left: 10px;
  }

  ul.pagination li:not(.pageNumber) .prev {
    margin-right: 10px;
  }

  .e4-pagination .e4p-left ul {
    justify-content: center;
  }
  .contactUs-form {
    padding: 25px 15px;
  }
  .video-list-section.emailUs-section {
    background: transparent;
    padding: 100px 0 50px;
  }
  .getContent-img {
    padding: 30px 0;
  }
  .gc-left {
    padding-left: 15px;
  }
  .getContent-item h4 {
    margin-bottom: 12px;
  }
  .startEaring-img img {
    width: auto;
  }

  .startEaring-img img {
    width: auto;
  }

  .enhanceProfile-inner {
    padding: 60px 15px 0;
  }

  .eps-left {
    margin-right: 0;
  }

  .bell-btn .dropdown-menu .dropdown-menu-inner .notify-list {
    height: 340px;
  }
  .npr-bottom .btn.notify-ac-btn {
    width: 100%;
  }
  .scale_cus_modal {
    padding: 20px 0 !important;
  }

  .chatUser-right-head span {
    font-size: 14px;
    text-align: center;
  }

  .chatUser-right-head span:first-child {
    margin-bottom: 3px;
  }

  .chatUser-right-head {
    background: #e4e4e4;
    margin: 0;
    padding: 5px 0;
  }

  .chatHead-user-details h5 {
    font-size: 20px;
  }

  .chatUser-left-head .chatUser-img img {
    width: 55px;
    height: 55px;
  }

  .chatUser-left-head {
    padding: 12px;
  }

  .messanger-message .chat-block {
    height: calc(100vh - 340px);
    overflow-y: auto;
  }

  .sc-title span {
    font-size: 18px;
    line-height: 1.5;
  }
  .ardCatD-head .dropDwon-quote-block.right {
    width: 100%;
    flex-direction: initial;
    position: relative;
  }

  .ardCatD-head .dropDwon-quote-block.right .dotsDropdown-menu {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  .dotsDropdown-menu .dd-btn {
    font-size: 28px;
  }
  .home-happening-list,
  .howItWork-section,
  .people-voice-section {
    overflow-x: hidden;
  }
  .serviceCat-list-wrap .service-cat-item .sc-head a {
    pointer-events: none;
  }
  .serviceCat-list-wrap .service-cat-item {
    padding-bottom: 40px;
  }
}

@media (max-width: 710px) {
  .userBtn-icon{
    /* width: 100%;
    display: flex;
    justify-content: end; */
    margin-left: 40px;
  }

  .adv-gallery-video{
    width: 200px;
    height: 100px;
  }

  
}

@media (max-width: 710px) {

#userMenuButton{
  flex-direction: column;
}

.hb-search-form {
 
  margin-top: 5px;
}

.service-cat-section{
  margin-top: -150px
}

.submit-quote-home{
  margin-top: 10px;
}

.ardCat-submitQ-btn2{
  width: 150px;
  font-size: 14px
}

}

@media (max-width: 1400px) {
.sc-body .btn{
  transform: translate(0px, 0%);
  position: relative;
}

.startEaring-tour-section{
  margin-top: 0px;
}
}

