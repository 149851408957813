/******** Common CSS Start ********/
body {
  font-family: "SF UI Display";
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #1b262c;
  overflow-x: hidden;
  overflow-y: visible;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay-Medium.woff2") format("woff2"),
    url("../fonts/SFUIDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFUIDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay-Light.woff2") format("woff2"),
    url("../fonts/SFUIDisplay-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay-Semibold.woff2") format("woff2"),
    url("../fonts/SFUIDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay-Bold.woff2") format("woff2"),
    url("../fonts/SFUIDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
.h1 {
  font-size: 58px;
  font-weight: bold;
}
h2,
.h2 {
  font-size: 42px;
}
h3,
.h3 {
  font-size: 32px;
}
h4,
.h4 {
  font-size: 28px;
}
h5,
.h5 {
  font-size: 20px;
}
h6,
.h6 {
  font-size: 18px;
}
.btn.focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}
a,
.btn,
button {
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
a:hover {
  text-decoration: none;
}
button:focus,
a:focus {
  outline: none;
}
.form-control {
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

::placeholder {
  color: #9ba4b4 !important;
  font-size: 18px;
  margin: 0;
  font-weight: normal;
}

:-ms-input-placeholder {
  color: #9ba4b4 !important;
  font-size: 18px;
  margin: 0;
  font-weight: normal;
}

::-ms-input-placeholder {
  color: #9ba4b4 !important;
  font-size: 18px;
  margin: 0;
  font-weight: normal;
}
input:focus,
.form-control:focus,
.input-desc-hover .phoneNo-code-block .form-control {
  outline: none;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  border-color: #ef4339;
  background-color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.form-control.datepicker {
  background-image: url(../images/calendar-light-icon.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px 20px;
  padding-right: 35px;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e5eff0;
  opacity: 1;
}
.mt-30 {
  margin-top: 30px;
}
.ml-30 {
  margin-left: 30px;
}
.pad-tb-50 {
  padding: 50px 0;
}
.pad-t-50 {
  padding-top: 50px;
}
.pad-b-50 {
  padding-bottom: 50px;
}
.pad-t-100 {
  padding-top: 100px;
}
.pad-b-100 {
  padding-bottom: 100px;
}
.pad-tb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.btn {
  border-radius: 100px;
  font-weight: 600;
  font-size: 20px;
}
.btn-sm,
.btn-md {
  padding: 14px 5px;
  font-size: 20px;
}
.btn-sm {
  width: 150px;
}
.btn-md {
  width: 220px;
}
.btn-red,
.btn-orange:hover {
  color: #fff;
  background: #ef4339;
  /* margin: 1%; */
}
.btn-red:hover,
.btn-orange {
  background: #ff8805;
  color: #fff;
}
.btn-black,
.btn-black:hover {
  color: #fff;
  background: #000000;
}
.text-orange {
  color: #ff8805;
}
textarea.form-control {
  padding: 12px 20px;
}
.section-title h2 {
  line-height: 62px;
  margin-bottom: 10px;
}
.selectAdd-input .text-truncate {
  display: block;
}
.section-des p {
  margin: 0;
  font-size: 24px;
  color: #1b262c;
}

.ardCatD-it-action a {
  font-size: 18px;
}
.btn-wpad.ardCat-submitQ-btn {
  width: auto;
  padding: 14px 20px;
}
.delay-1s {
  animation-delay: 0.1s;
}

.delay-2s {
  animation-delay: 0.2s;
}

.delay-3s {
  animation-delay: 0.3s;
}

.delay-4s {
  animation-delay: 0.4s;
}

.delay-5s {
  animation-delay: 0.5s;
}

.delay-6s {
  animation-delay: 0.6s;
}

.delay-7s {
  animation-delay: 0.7s;
}
/******** Common CSS End ********/

/******** Change Container Size ********/

@media (min-width: 1366px) {
  .container {
    max-width: 1240px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1366px;
  }
}
/******** Change Container Size ********/

/******** Home Banner Start  ********/
.home-banner-section {
  background: #000;
  padding: 160px 0 120px;
  position: relative;
  overflow: hidden;
}

.home-banner-section:after {
  width: 100%;
  height: 0;
  border-bottom: 188px solid white;
  border-right: 1920px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.home-banner-section:before {
  border: 50px solid #3b3b3b;
  height: 335px;
  width: 335px;
  bottom: -148px;
  right: -100px;
}

.home-banner-section:before,
.circle-box:before,
.circle-box:after {
  content: "";
  background: transparent;
  border-radius: 100%;
  display: block;
  position: absolute;
  opacity: 0.7;
}

.circle-box:after {
  border: 80px solid #3b3b3b;
  height: 680px;
  width: 680px;
  top: -290px;
  left: -90px;
}

.circle-box:before {
  border: 54px solid #3b3b3b;
  height: 380px;
  width: 380px;
  top: -168px;
  right: -60px;
}

.hb-caption {
  margin: 75px 70px 0 0;
}
.hb-caption h1 {
  line-height: 88px;
  color: #fff;
}
.hb-caption h1 span {
  color: #ff8805;
}

.hb-search-form {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 30px;
}
.hb-search-form,
.hb-search-form .form-group {
  display: flex;
  align-items: center;
}
.hb-search-form .form-group {
  margin: 0;
  width: calc(100% - 150px);
}
.hb-search-form .form-group .form-group-inner {
  width: 50%;
}
.input-control-icon {
  color: #0779e4;
  width: 40px;
  line-height: 45px;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}
.input-control-icon ~ input {
  padding-left: 50px;
}
.hb-search-form .form-control {
  height: 45px;
  border: none;
}

.hb-search-form .form-group-inner:first-child .form-control {
  border-right: 1px solid #dad9d9;
}
.hb-search-form .btn {
  border-radius: 0;
}

.hb-down-arrow {
  background: transparent;
  border: 2px solid #9ba4b4;
  width: 40px;
  height: 62px;
  border-radius: 22px;
  position: relative;
  margin-top: 85px;
}

.hb-down-arrow:after {
  content: "";
  width: 3px;
  height: 15px;
  background: #ff8805;
  display: block;
  border-radius: 100px;
  position: absolute;
  top: 12px;
  bottom: 12px;
  margin: 0 auto;
  left: 0;
  right: 0;
  animation: moveArrow 2.5s infinite;
}
@-webkit-keyframes moveArrow {
  0% {
    top: 12px;
  }
  50% {
    top: 30px;
  }
  100% {
    top: 12px;
  }
}
@keyframes moveArrow {
  0% {
    top: 12px;
  }
  50% {
    top: 30px;
  }
  100% {
    top: 12px;
  }
}
/******** Home Banner End  ********/

/******** Categories Section Start ********/
.cat-color-1 {
  background: #fcf1e4;
  border: 1px solid #ff8805;
}
.cat-color-2 {
  background: #e9ffff;
  border: 1px solid #8bcdcd;
}
.cat-color-3 {
  background: #dff0ff;
  border: 1px solid #0779e4;
}
.cat-color-4 {
  background: #feebeb;
  border: 1px solid #ffa5a5;
}
.cat-color-5 {
  background: #fff5e4;
  border: 1px solid #ffbd43;
}
.cat-color-6 {
  background: #fff3f1;
  border: 1px solid #ff5733;
}
.cat-color-7 {
  background: #e9fffe;
  border: 1px solid #208582;
}
.cat-color-8 {
  background: #ececff;
  border: 1px solid #484898;
}
.cat-color-9 {
  background: #ffeef4;
  border: 1px solid #900c3e;
}
.cat-color-1 .sc-icon,
.sc-item.cat-color-1:after {
  background: #ff8805;
  border: 1px solid #fcf1e4;
}
.cat-color-2 .sc-icon,
.sc-item.cat-color-2:after {
  background: #8bcdcd;
  border: 1px solid #e9ffff;
}
.cat-color-3 .sc-icon,
.sc-item.cat-color-3:after {
  background: #0779e4;
  border: 1px solid #dff0ff;
}
.cat-color-4 .sc-icon,
.sc-item.cat-color-4:after {
  background: #ffa5a5;
  border: 1px solid #feebeb;
}
.cat-color-5 .sc-icon,
.sc-item.cat-color-5:after {
  background: #ffbd43;
  border: 1px solid #fff5e4;
}
.cat-color-6 .sc-icon,
.sc-item.cat-color-6:after {
  background: #ff5733;
  border: 1px solid #fff3f1;
}
.cat-color-7 .sc-icon,
.sc-item.cat-color-7:after {
  background: #208582;
  border: 1px solid #e9fffe;
}
.cat-color-8 .sc-icon,
.sc-item.cat-color-8:after {
  background: #484898;
  border: 1px solid #ececff;
}
.cat-color-9 .sc-icon,
.sc-item.cat-color-9:after {
  background: #900c3e;
  border: 1px solid #ffeef4;
}
.service-cat-section {
  margin-top: -57px;
}
.serviceCat-slider {
  width: 102em;
  margin-top: 70px;
  padding-left: 160px;
}
.sc-item {
  border-radius: 10px;
  padding: 17px 13px 17px 15px;
  width: 180px !important;
  min-height: 180px;
  margin-right: 40px;
  position: relative;
  overflow: hidden;
}
.sc-item:after {
  content: "";
  height: 180px;
  width: 180px;
  display: block;
  position: absolute;
  top: -100%;
  left: -100%;
  transition: all 0.4s ease-in-out;
  border-radius: 10px;
  border: none !important;
}
.sc-item:hover:after {
  left: 0;
  top: 0;
}
.sc-item .sc-circle-img {
  position: absolute;
  left: -1px;
  top: 0;
}
.sc-title {
  display: flex;
  align-items: center;
  min-height: 56px;
}
.sc-item .sc-icon {
  margin-bottom: 17px;
  width: 70px;
  height: 70px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: relative;
  z-index: 1;
}
.sc-title span {
  font-size: 21px;
  line-height: 28px;
  font-weight: 500;
  color: #1b262c;
  transition: all 0.4s ease-in-out;
  position: relative;
  z-index: 1;
}
.sc-item:hover .sc-title span {
  color: #fff;
}
.slick-arrow-control {
  border: 2px solid #9ba4b4;
  background: #fff;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  font-size: 21px;
  color: #9ba4b4;
  position: absolute;
  left: 30px;
  cursor: pointer;
}
.slick-arrow-control:hover {
  border-color: #ff8805;
  color: #ff8805;
}
.slick-arrow-control.prev-control {
  top: 95px;
}
.slick-arrow-control.next-control {
  bottom: 25px;
}
/******** Categories Section End ********/

/******** Advertisement Section Start ********/
.img-w-100 {
  width: 100%;
}

.adv-gallery-list
.adv-gallery-col{
position: relative;
}

.adv-gallery-list,
.adv-gallery-col.flex-gallery {
  display: flex;
}

.adv-gallery-list .adv-gallery-left {
  margin-right: 30px;
  width: 626px;
  height:auto
}
/******** Advertisement Section End ********/

/******** How It Work Section Start ********/
.howItWork-section {
  background: url("../images/how-work-bg.svg") no-repeat;
  background-position: bottom 8% center;
}
.hiw-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
}
.hiw-item {
  width: calc(33.33% - 56px);
  margin-right: 83px;
  background: #ffffff;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(155, 164, 180, 0.16);
  -moz-box-shadow: 0px 0px 40px 0px rgba(155, 164, 180, 0.16);
  box-shadow: 0px 0px 40px 0px rgba(155, 164, 180, 0.16);
}
.hiw-list .hiw-item:last-child {
  margin-right: 0;
}
.hiw-item .hiw-icon {
  padding: 20px 10px 15px;
}
.aboutUs-col-list .hiw-item .hiw-icon {
  padding: 40px 10px;
}
.hiw-title {
  background: #e5eff0;
  padding: 12px;
}

.hiw-title h3 {
  font-size: 24px;
  font-weight: 500;
  color: #1b262c;
  text-align: center;
}

.hiw-inner-content {
  padding: 30px 32px 48px;
  text-align: center;
}

.hiw-inner-content p {
  font-size: 18px;
  color: #1b262c;
  margin: 0;
}
/******** How It Work Section End ********/

/******** About Section Start ********/
.ha-content-box {
  padding-left: 100px;
}
.ha-content-box h5 {
  font-size: 24px;
  font-weight: normal;
}
.ha-content-box p {
  font-size: 18px;
  margin-top: 30px;
}

.ha-content-box ul {
  list-style: none;
  padding: 0;
  margin: 40px 0;
}

.ha-content-box ul li {
  margin-bottom: 25px;
}

.ha-content-box ul li:last-child {
  margin-bottom: 0;
}

.ha-content-box ul li img {
  margin-right: 20px;
  vertical-align: text-bottom;
}

.ha-content-box ul li span {
  font-size: 28px;
  color: #646464;
}

.ha-content-box ul li span b {
  color: #000;
}
/******** About Section End ********/

/******** People Voice Section Start ********/
.people-voice-section {
  padding: 170px 0;
}
.people-voice-section,
.home-happening-list {
  background: #f6f6f6;
  position: relative;
  background-image: url(../images/shape-bg.svg);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.people-voice-section:after,
.people-voice-section:before,
.home-happening-list:after,
.home-happening-list:before {
  width: 100%;
  height: 0;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
}

@media (max-width: 1200px) {
  .people-voice-section:after,
.people-voice-section:before,
.home-happening-list:after,
.home-happening-list:before {
 
  content: none;

}

}

.people-voice-section:after,
.home-happening-list:after {
  border-top: 180px solid white;
  border-left: 1920px solid transparent;
  top: 0;
}
.people-voice-section:before,
.home-happening-list:before {
  border-bottom: 180px solid white;
  border-right: 1920px solid transparent;
  bottom: 0;
}
.people-voice-slider {
  padding: 0 60px 0 100px;
  margin-top: 70px;
}
.voice-item {
  padding: 10px 60px 10px 10px;
}
.voice-item .voice-img {
  position: relative;
}
.voice-item .voice-img:after,
.voice-item .voice-img:before {
  content: "";
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: -1;
}
.voice-item .voice-img:after {
  background: #ef4339;
  left: -10px;
  top: -10px;
}
.voice-item .voice-img:before {
  background: #ff8805;
  right: -10px;
  bottom: -10px;
}
.voice-item .voice-img img {
  width: 100%;
  height: 324px;
  object-fit: cover;
  border-radius: 10px;
}
.voice-videoPlay-block {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.voice-videoPlay-block a {
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  color: black;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #fff;
  border-radius: 100px;
  font-size: 30px;
}
.voice-videoPlay-block a:hover {
  background: rgba(255, 136, 5, 0.5);
  border: 1px solid #ff8805;
}
.people-voice-slider .slick-arrow-control.prev-control,
.people-voice-slider .slick-arrow-control.next-control {
  top: 140px;
}
.people-voice-slider .slick-arrow-control.prev-control {
  left: 0;
}
.people-voice-slider .slick-arrow-control.next-control {
  right: 0;
  left: auto;
}

.people-voice-slider ul.slick-dots li button {
  width: 13px;
  height: 13px;
  background: #9ba4b4;
  border: none;
  font-size: 0;
  border-radius: 100px;
  padding: 0;
  margin: 0 5px;
}

.people-voice-slider ul.slick-dots {
  list-style: none;
  padding: 0;
  margin: 50px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.people-voice-slider ul.slick-dots li.slick-active button {
  background: #ef4339;
}

.videoModal-box .modal-dialog {
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto;
}
.videoModal-box .videoModal-body,
.videoModal-body .embed-responsive {
  border-radius: 20px;
}
.videoModal-box .modal-content {
  background: transparent;
  border: none;
}
/******** People Voice Section End ********/

/******** Happening Seciton Start ********/
.happening-section .section-des {
  margin-bottom: -50px;
  position: relative;
  z-index: 2;
}
.home-happening-list {
  padding: 120px 0;
}
/*.happening-slider {
    white-space: nowrap;
    transition: all .2s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000;
    transform-style: preserve-3d;
    position: relative;
    z-index: 9;
}
.happening-slider.moving-left{
    -webkit-animation: slide 200s linear infinite;
    animation: slide 200s linear infinite;
}
.happening-slider.moving-right {
    -webkit-animation: slideReverse 200s linear infinite;
    animation: slideReverse 200s linear infinite;
    float: right;
    padding-top: 90px;
}
.happening-slider:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.happening-slider .happening-inner-slider{
    margin: 0 auto;
    display: inline-block;
    align-items: center;
}
.happening-slider.moving-right .happening-inner-slider{
    transform: translateX(5%);   
}
@-webkit-keyframes slide {
    0% {
        transform: translateZ(0)
    }
    to {
        transform: translate3d(-1920px, 0, 0)
    }
}

@keyframes slide {
    0% {
        transform: translateZ(0)
    }
    to {
        transform: translate3d(-1920px, 0, 0)
    }
}

@-webkit-keyframes slideReverse {
    0% {
        transform: translateZ(0)
    }
    to {
        transform: translate3d(1920px, 0, 0)
    }
}

@keyframes slideReverse {
    0% {
        transform: translateZ(0)
    }
    to {
        transform: translate3d(1920px, 0, 0)
    }
}*/
.happening-slider {
  position: relative;
  z-index: 1;
}
.happening-slider.moving-right {
  direction: ltr;
  padding-top: 90px;
}
.happening-slider .slick-slide {
  width: 245px;
  margin-right: 30px;
}
.happening-inner-slider .slick-list {
  overflow: visible;
}
.happening-item {
  padding: 10px 15px 15px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(155, 164, 180, 0.16);
  -moz-box-shadow: 0px 0px 40px 0px rgba(155, 164, 180, 0.16);
  box-shadow: 0px 0px 40px 0px rgba(155, 164, 180, 0.16);
  border-radius: 20px;
  position: relative;
  border: 1px solid #e5eff0;
  display: inline-block;
  margin-top: 50px;
  width: 240px;
  /* height: 240px; */
}
.happening-item:hover {
  box-shadow: 0px 0px 40px 0px rgb(111 100 100 / 30%);
  transform: scale(1.05);
}
.happening-user-img {
  position: absolute;
  top: -40px;
}
.happening-user-img img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  border: 1px solid #e5eff0;
}
.happening-content {
  white-space: normal;
}
.happening-content .rating {
  text-align: right;
  margin-bottom: 20px;
}
.happening-content .rating .star-fill {
  color: #ff8805;
}
.happening-content .rating i {
  color: #9ba4b4;
}
.happening-content .rating .rating-count {
  font-size: 18px;
  color: #646464;
  margin-left: 5px;
  vertical-align: middle;
}
.happening-inner-content .location {
  font-size: 18px;
  color: #646464;
  display: block;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.happening-inner-content .service-type {
  margin: 6px 0 2px;
  font-size: 17px;
  text-align: center;
  display: block;
  font-weight: bold;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
.happening-inner-content .service-des {
  color: #646464;
  text-align: center;
  font-size: 16px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.happening-inner-content {
  padding: 0 10px;
}
.happening-more {
  margin-top: 20px;
}
/******** Happening Seciton End ********/

/******** Feature Section Start ********/
.ourFeature-list {
  display: flex;
  flex-wrap: wrap;
}
.ourFeature-item {
  padding: 20px 23px 14px 20px;
  border-radius: 20px;
  width: calc(50% - 10px);
  margin: 30px 20px 0 0;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(155, 164, 180, 0.16);
  -moz-box-shadow: 0px 0px 40px 0px rgba(155, 164, 180, 0.16);
  box-shadow: 0px 0px 40px 0px rgba(155, 164, 180, 0.16);
  -webkit-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.ourFeature-item:hover {
  background: #ef4339;
}

.ourFeature-item:nth-child(even) {
  margin-right: 0;
}

.ourFeature-item .title h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 17px 0 10px;
}

.ourFeature-item p {
  margin: 0;
}

.ourFeature-item:hover h3,
.ourFeature-item:hover p {
  color: #fff;
}

.ourFeature-item:hover .hover-fill-white {
  fill: #fff;
}

.ourFeature-item:hover .hover-stroke-white {
  stroke: #fff;
}
/******** Feature Section End ********/

/******** Footer Start ********/
section.getInTouch-section {
  padding-top: 70px;
  position: relative;
}
.getInTouch-section:after {
  content: "";
  background: #ff8805;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 680px;
  height: 162px;
  z-index: 1;
  border-radius: 0 20px 20px 0;
}
.getInTouch-box {
  background: #ff8805;
  border-radius: 0 20px 20px 0;
  width: 400px;
  position: absolute;
  z-index: 9;
  padding: 20px 80px 20px 0;
  margin-top: -70px;
  min-height: 162px;
}
.getInTouch-box .title h4 {
  font-size: 26px;
  color: #fff;
  margin-bottom: 0;
  line-height: 30px;
  margin-bottom: 5px;
}
.getInTouch-box .title p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
}
.social-list {
  display: flex;
  align-items: center;
}
.social-list a {
  display: inline-block;
  width: 45px;
  height: 45px;
  border: 1px solid #fff;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
}
.social-list a:hover {
  background: #fff;
  color: #ff8805;
}
.social-list a:last-child {
  margin: 0;
}

footer {
  background: #000;
  overflow: hidden;
  position: relative;
}
footer:after {
  width: 100%;
  height: 0;
  border-top: 188px solid #fff;
  border-left: 1920px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.footer-circle-box:before,
.footer-circle-box:after {
  content: "";
  background: transparent;
  border-radius: 100%;
  display: block;
  position: absolute;
  opacity: 0.7;
}
.footer-circle-box:before {
  border: 50px solid #3b3b3b;
  height: 335px;
  width: 335px;
  bottom: -206px;
  right: -122px;
}

.footer-circle-box:after {
  border: 80px solid #3b3b3b;
  height: 677px;
  width: 677px;
  top: -389px;
  left: -168px;
}

.footer-top-row {
  padding-top: 200px;
  padding-bottom: 45px;
}
.footer-app-link a {
  display: inline-block;
}
.footer-app-link a:hover {
  transform: translate(0, -8px);
}
.footer-app-link a:first-child {
  margin-right: 40px;
}
.footer-bottom {
  padding: 22px 0;
  border-top: 1px solid rgb(255 255 255 / 0.2);
}
.footer-bottom,
.footer-br ul,
.footer-link ul,
.footer-link-block {
  display: flex;
  align-items: center;
}
.footer-bottom,
.footer-link-block {
  justify-content: space-between;
}
.footer-bl span {
  color: #9ba4b4;
  font-size: 16px;
}
.footer-title h4 {
  font-size: 18px;
  color: #9ba4b4;
  margin-bottom: 9px;
}
.footer-email {
  margin-right: 80px;
}
.footer-link ul,
.footer-br ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-br ul li {
  margin-right: 20px;
}
.footer-link ul li {
  margin-right: 40px;
}
.footer-br ul li:last-child,
.footer-link ul li:last-child {
  margin-right: 0;
}
.footer-br ul li a,
.footer-link ul li a,
.footer-email a {
  font-size: 16px;
  color: #fff;
  display: inline-block;
}
.footer-br ul li a:hover,
.footer-link ul li a:hover,
.footer-email a:hover {
  color: #ff8805;
  transform: translate(10px, 0);
}

#return-to-top {
  background: #ff8805;
  bottom: 0;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 20px;
  height: 45px;
  line-height: 50px;
  position: fixed;
  right: 25px;
  text-align: center;
  width: 50px;
  z-index: 9999;
  border-radius: 6px 6px 0 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
#return-to-top:hover i {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
/******** Footer End ********/

/******** About Us Page **********/

.inner-banner-section {
  background: #000;
  padding: 150px 0 160px;
  position: relative;
  overflow: hidden;
}
.inner-banner-section:after {
  width: 100%;
  height: 0;
  border-bottom: 188px solid white;
  border-right: 1920px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.inner-banner-section:before {
  content: "";
  background: transparent;
  border-radius: 100%;
  display: block;
  position: absolute;
  opacity: 0.7;
  border: 50px solid #3b3b3b;
  height: 335px;
  width: 335px;
  bottom: -148px;
  right: -100px;
}
.inner-caption h1 {
  line-height: 88px;
  color: #fff;
  margin-bottom: 10px;
}
.inner-caption h1 span {
  color: #ff8805;
}
.inner-caption p {
  font-size: 24px;
  color: #ffff;
  margin: 0;
}

.inner-caption .orange-text {
  color: #ef4339;
  font-size: 24px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.au-content-box h5 {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 30px;
}
.au-des-content {
  margin-bottom: 20px;
}
.au-des-content p {
  font-size: 18px;
  color: #1b262c;
}
.au-app-link a {
  display: inline-block;
}
.au-app-link a:first-child {
  margin-right: 40px;
}
.au-app-link a:hover {
  transform: translate(0, -8px);
}
.video-list-section {
  background: url("../images/about-bg.svg") no-repeat bottom center;
  padding-top: 110px;
  margin-bottom: -257px;
  position: relative;
  z-index: 1;
  padding-bottom: 310px;
}
.video-block {
  padding: 10px;
  margin-bottom: 50px;
}
.video-block .video-img {
  position: relative;
}
.video-block .video-img:after,
.video-block .video-img:before {
  content: "";
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 1;
}
.video-block .video-img:before {
  background: #ff8805;
  right: -10px;
  bottom: -10px;
}
.video-block .video-img:after {
  background: #ef4339;
  left: -10px;
  top: -10px;
}

.video-block .video-img img {
  width: 100%;
  height: 650px;
  object-fit: cover;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

/*************** How IT Work *********************/
.work-process-section,
.ezuntionFeed-section {
  position: relative;
}
.work-process-section .wp-clip-bg,
.peopleVoice-page-section .wp-clip-bg,
.ezuntionFeed-section .wp-clip-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: -106px;
}
.workProcess-row:nth-child(1) {
  padding-top: 70px;
}
.workProcess-row:nth-child(2),
.workProcess-row:nth-child(3) {
  padding-top: 100px;
}
.workProcess-row:nth-child(odd) .work-process-content {
  padding-left: 70px;
}
.workProcess-row:nth-child(even) .work-process-content {
  padding-right: 70px;
}
.work-process-content .title h2 {
  font-size: 28px;
  color: #1b262c;
  margin-bottom: 30px;
}

.work-process-content .des-list ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.work-process-content .des-list ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.work-process-content .des-list ul li:last-child {
  margin: 0;
}

.work-process-content .des-list ul li img {
  width: 22px;
  margin: 3px 15px 0 0;
}

.work-process-content .des-list ul li span {
  font-size: 18px;
  display: block;
  color: #1b262c;
}

/*************** Service Categories **********************/
.service-cat-list {
  display: flex;
  flex-wrap: wrap;
}
.service-cat-item {
  margin: 15px 0;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: calc(33.33% - 36px);
  margin-right: 53px;
}

.service-cat-item:nth-child(3n + 0) {
  margin-right: 0;
}
.service-cat-inner {
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}
a.service-cat-inner {
  display: block;
}
.service-cat-item:hover .service-cat-inner,
.service-cat-item .service-cat-inner.open-quote {
  border-color: #ff8805;
}
.service-cat-item .sc-head {
  background: #f6f6f6;
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid #d2d2d2;
  display: flex;
  align-items: center;
  padding: 14px 20px;
  transition: all 0.3s ease-in-out;
}
.service-cat-item:hover .sc-head,
.service-cat-item .service-cat-inner.open-quote .sc-head {
  background: rgb(255, 136, 5, 0.1);
  border-color: #ff8805;
}
.service-cat-item .sc-head .left img {
  border-radius: 100px;
  height: 80px;
  width: 80px;
  border: 1px solid #e5eff0;
}
.service-cat-item .sc-head .right {
  margin-left: 20px;
}
.service-cat-item .sc-head .right h3 {
  font-size: 24px;
  font-weight: bold;
  line-height: 38px;
}
.service-cat-item .sc-head .right h3 a,
.service-cat-item .sc-head .right h3 {
  color: #1b262c;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}
.scr-location {
  font-size: 20px;
  color: #646464;
  display: block;
}
.sc-head .rating i {
  color: #9ba4b4;
}
.rating i.star-fill,
.mfiL-content .rating i.star-fill {
  color: #ff8805;
}
.sc-head .rating-count {
  margin-left: 5px;
  color: #646464;
  font-size: 19px;
  vertical-align: middle;
}
.sc-body {
  padding: 20px;
  transition: all 0.3s ease-in-out;
}
.service-cat-item:not(.hnc-item):hover .sc-body {
  padding-bottom: 53px;
}
.scb-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.scb-top .service-type,
.service-status {
  font-size: 20px;
  color: #ef4339;
  display: block;
}
.scb-top .experience {
  color: #1b262c;
  font-size: 20px;
  font-weight: bold;
}
.scb-bottom span {
  color: #646464;
  font-size: 20px;
  display: block;
}
.scb-bottom span.sc-name {
  color: #000;
  font-weight: bold;
  margin-bottom: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ellipsis-format{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sc-body .btn {
  transform: translate(0px, 150%);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
}
.service-cat-item:hover .sc-body .btn {
  transform: translate(0px, 40%);
}
.service-status {
  line-height: 1;
  padding: 5px 0 14px;
}
.scb-bottom span.f-24 {
  font-size: 24px;
}
.service-cat-inner.open-request {
  border-color: #ff8805;
}
.open-request .sc-name,
.open-quote .sc-head {
  position: relative;
}
.open-request .sc-name:after,
.open-quote .sc-head:after {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 5px;
}
.open-quote .sc-head:after {
  top: 50%;
  transform: translate(0, -50%);
  color: #1b262c;
  right: 15px;
}

.serviceCat-banner-wrap.inner-banner-section:after {
  border-bottom-color: #f6f6f6;
}
.service-filter-section {
  background: #f6f6f6;
  padding: 50px 0;
  position: relative;
}

.service-filter-section .wp-clip-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: -120px;
}
.service-filter-section .form-group {
  margin: 0;
}
select.form-control {
  padding: 12px 38px 12px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-image: url(../images/select-arrow.svg);
  color: #9ba4b4;
  font-size: 18px;
  margin: 0;
  font-weight: normal;
}
.service-filter-section .form-control {
  background-color: #fff;
  border-color: #e5eff0;
  border-radius: 5px;
  height: 60px;
}

.service-filter-section label {
  font-size: 18px;
  font-weight: normal;
}

.searchAction-block .search-filter-btn {
  width: 60px;
  height: 60px;
  padding: 0;
  margin-left: 25px;
}

.catOpen-filter-section {
  transition: all 0.3s ease-in-out;
}
.catOpen-filter-section:not(.open) {
  transform: scale(0);
  height: 0;
}
.catOpen-filter-section.open {
  margin-top: 20px;
}
.cat-open-filter {
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 0 0 5px 5px;
  padding: 30px 35px;
  position: relative;
}

.cof-close-block {
  position: absolute;
  right: 40px;
  top: -20px;
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
}

.cof-close-block .cofClose-btn {
  background: transparent;
  border: none;
  font-size: 22px;
  color: #9ba4b4;
}

/*.serviceCat-filter-block .cof-close-block .cofClose-btn, */
.cof-close-block .cofClose-btn:after {
  content: "";
  background: #fff;
  height: 20px;
  width: 42px;
  position: absolute;
  left: -1px;
  right: 0;
  bottom: -2px;
}
.serviceCat-filter-block.catOpen-filter-section.open {
  margin: 0;
}
.catOpen-filter-section.serviceCat-filter-block.removeTop-margin {
  position: relative;
  top: -50px;
}
.cof-close-block .cofClose-btn i {
  position: relative;
  z-index: 2;
}

.cof-close-block .cofClose-btn:hover i {
  color: #ef4339;
}
.cat-open-filter label {
  font-size: 18px;
  font-weight: normal;
}
.cat-open-filter .form-control {
  border-color: #e5eff0;
  border-radius: 5px;
  height: 60px;
  background-color: #fbfbfb;
}
.cat-open-filter .row .form-group {
  padding: 0 10px;
  margin-bottom: 32px;
}

.cat-open-filter .radio-label-control {
  margin-right: 15px;
}

/*************** Service Categories Details **********************/
.spd-hero-section {
  background: #f6f6f6;
  position: relative;
  padding-top: 60px;
}

.spd-hero-section .spd-clip-bg {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
}
.spdh-inner {
  display: flex;
  align-items: flex-end;
}
.spdh-right {
  width: 100%;
}
.spdh-user-name h1 {
  font-size: 42px;
  margin-bottom: 10px;
  color: #1b262c;
}
.spdh-star {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.spdh-star i {
  color: #9ba4b4;
  margin-right: 7px;
}
.spdh-star i.star-fill {
  color: #ff8805;
}

span.spdh-count {
  font-size: 22px;
  color: #646464;
  margin: 2px 0 0 5px;
}

.spdh-location {
  font-size: 22px;
  color: #646464;
  margin-top: 5px;
  display: block;
}
.spdh-user-img {
  margin: 0 50px -80px 0;
  position: relative;
  z-index: 2;
}
.spdh-user-img img {
  min-width: 420px;
  width: 100%;
  height: 420px;
  object-fit: cover;
  border-radius: 100%;
}
.spdh-status {
  display: flex;
  margin-top: 35px;
  justify-content: space-around;
}
.spdh-status .spdh-status-item:first-child {
  background: #ff8805;
  border-top-left-radius: 20px;
}
.spdh-status .spdh-status-item {
  width: 100%;
  text-align: center;
  font-size: 26px;
  padding: 22px 10px;
  color: #fff;
}
.spdh-status .spdh-status-item span strong {
  font-weight: 600;
}
.spdh-status .spdh-status-item:last-child {
  background: #ef4339;
  border-top-right-radius: 20px;
}

.spd-profileInf-section {
  padding: 190px 0 70px;
}
.spd-profileInf-section .section-title h2,
.review-rating-section .section-title h2 {
  font-size: 42px;
  color: #1b262c;
  font-weight: normal;
  margin-bottom: 20px;
}
.spdp-info-list {
  border-radius: 20px;
  border: 1px solid #d2d2d2;
  padding: 40px;
}
.spdp-info-item + .spdp-info-item {
  margin-top: 50px;
}
.spdp-flex {
  display: flex;
  align-items: center;
}

.spdp-flex.spdp-top,
.spdp-flex.spdp-center {
  justify-content: space-between;
}

.spdp-flex.spdp-top h3,
.spdp-flex.spdp-top .spdp-year {
  font-size: 28px;
  color: #1b262c;
  font-weight: 600;
}

.spdp-flex.spdp-center span {
  font-size: 22px;
}

.spdp-flex.spdp-center span:first-child {
  color: #1b262c;
}

.spdp-flex.spdp-center span:last-child {
  color: #646464;
}

.spdp-flex.spdp-bottom span {
  border: 1px solid #ff8805;
  font-size: 22px;
  background: rgb(255 136 5 / 0.3);
  color: #1b262c;
  border-radius: 100px;
  padding: 7px 5px;
  width: 300px;
  text-align: center;
  margin-right: 30px;
}

.spdp-flex.spdp-center {
  margin: 18px 0 15px;
}
.review-rating-section {
  padding-bottom: 100px;
}
.rating-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d2d2d2;
  border-radius: 20px 20px 0 0;
  background: #f6f6f6;
  padding: 30px 40px;
}

.ratingHead-left .totalRate {
  font-size: 70px;
  font-weight: bold;
  color: #1b262c;
  line-height: 84px;
}

.tutorRating-block span {
  font-size: 24px;
  color: #1b262c;
  font-weight: 600;
  margin-left: 20px;
}

.tutorRating-block {
  display: flex;
  align-items: center;
}

.tutorRating-star i {
  color: #ff8805;
}
.rating-bars-item {
  display: flex;
  align-items: center;
  margin: 4px 0;
}
.rating-bars-item .rating-bars-name {
  color: #cecece;
  font-size: 16px;
}
.rating-bars-item .rating-bars {
  margin: 0 30px;
  position: relative;
}
.rating-bars .rating-stroke-line {
  width: 400px;
  height: 5px;
  background: #ffdbb3;
  display: block;
  border-radius: 100px;
}
.rating-bars .rating-fill-line {
  background: #ff8805;
  height: 5px;
  display: block;
  position: absolute;
  left: 0;
  border-radius: 100px;
  top: 0;
}
.rating-bars-count span {
  font-size: 15px;
  color: #000000;
  text-align: right;
  display: block;
}

.rating-bars-item .rating-bars-name i {
  color: #ff8805;
  font-size: 15px;
}

.rating-bars-item .rating-bars-name span {
  color: #000;
  font-size: 15px;
  margin-right: 5px;
}

.rating-body {
  border: 1px solid #d2d2d2;
  border-top: 0;
  border-radius: 0 0 20px 20px;
}

.rating-body .rating-item {
  padding: 20px 40px;
  border-top: 1px solid #d2d2d2;
}
.rating-body .rating-item:nth-child(1) {
  border: none;
}
.rating-item-left {
  display: flex;
  align-items: center;
}
.ratingCover img {
  border-radius: 100px;
  border: 1px solid #e5eff0;
}
.ratingCover {
  margin-right: 20px;
}
.rating-name-rate h4 {
  font-size: 24px;
  color: #1b262c;
}
.rating-name-rate .rating-star i {
  color: #9ba4b4;
  font-size: 15px;
}
.rating-name-rate .rating-star i.fill-star {
  color: #ff8805;
}
.rating-item-right {
  margin-top: 15px;
}
.rating-name-rate .rating-star span {
  color: #646464;
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px;
}
.review-content-block h5 {
  margin-bottom: 5px;
}
.review-content-block p {
  font-size: 20px;
  color: #1b262c;
  margin: 0;
}

.trackService-proovider-block img {
  border-radius: 20px;
  border: 1px solid #d2d2d2;
}

/************** Create My Request ***********/
.cmr-form-block,
.blankService-tab-go {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 50px 30px;
  border: 1px solid #d2d2d2;
  border-radius: 20px;
  margin-bottom: 100px;
}
.gray-hero-section {
  background: #f6f6f6;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
}
.gray-hero-section .gh-clip-bg {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
}
.gray-hero-section h1 {
  color: #000;
  line-height: 1.2;
}
.gray-hero-section p {
  color: #000;
}
.gray-hero-section.db-gh-section {
  padding: 30px 0 150px;
}
.db-top-menu {
  margin-top: -120px;
  padding-bottom: 70px;
}
.db-menu-block ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid #d2d2d2;
  overflow: hidden;
}
.db-menu-block ul li {
  width: 100%;
  border-left: 1px solid #d2d2d2;
}
.db-menu-block ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 10px;
  background: #ffffff;
  min-height: 130px;
  width: 100%;
}
.grayHero-tabnav .nav-tabs li a:not(active) {
  border: 1px solid #d2d2d2;
  border-bottom: 0;
}
.db-menu-block ul li:nth-child(1) {
  border-left: 0;
}
.db-menu-block ul li a h4 {
  color: #1b262c;
  font-size: 22px;
  font-weight: 500;
  margin: 18px 0 0;
}
.db-menu-block ul li a.active-db {
  background: #ff8805;
}
.db-menu-block ul li a.active-db h4 {
  color: #fff;
}
.db-menu-block ul li a.active-db img {
  filter: brightness(0) invert(1);
}

.textNote {
  font-size: 16px;
  color: #9ba4b4;
  display: block;
  margin-top: 10px;
}

.cmr-form-block .form-submit {
  margin-top: 40px;
}

.textarea-group {
  position: relative;
}

.textarea-limit {
  position: absolute;
  right: 20px;
  bottom: 15px;
  color: #9ba4b4;
}
.resize-none {
  resize: none;
}
.homeAddress-option,
.otherAddress-option {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.otherAddress-option {
  align-items: center;
}
.addNewAddress-request {
  text-align: right;
}
.addNewAddress-request .btn {
  padding: 0;
  font-size: 17px;
  display: block;
  margin-left: auto;
  color: #ef4339;
}

.addnewaddress-wrap .scm_body .form-group {
  margin: 0 10px 20px;
}
.selectAdd-input {
  padding: 10px 38px 10px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-image: url(../images/select-arrow.svg);
  color: #9ba4b4;
  font-size: 18px;
  margin: 0;
  font-weight: normal;
  border: 1px solid #e5eff0;
  border-radius: 5px;
  height: 60px;
  line-height: 40px;
  background-color: #fbfbfb;
}
.selectAdd-input:not(.cus-select-box) span {
  display: block;
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selectAdd-group {
  position: relative;
}
.selectAdd-option-group {
  background: #fbfbfb;
  border: 1px solid #e5eff0;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}
.selectAdd-option-group .sao {
  padding: 15px 20px;
  cursor: pointer;
}
.selectAdd-option-group .sao strong {
  font-size: 20px;
  display: block;
  margin-bottom: 6px;
}
.selectAdd-option-group .sao span {
  font-size: 20px;
  color: #646464;
  display: block;
}
.selectAdd-option-group .sao:hover {
  background: #f5f5f5;
}

/************ Request Details Page *****************/
.gray-hero-section.requestDetail-hero-section .gh-clip-bg {
  top: -50px;
}
.requestDetail-hero-section .form-group {
  margin: 0;
}
.requestDetail-section {
  padding-bottom: 100px;
}
.ardCat-list {
  position: sticky;
  top: 70px;
  height: 900px;
}
.oneReq-section .ardCat-list {
  height: auto;
}
.ard-cat-item {
  padding: 0;
  margin: 0 0 20px;
}
.myRq-tab-content {
  display: none;
}
.myRq-tab-content.current-request {
  display: block;
}
.ardCat-detail-right {
  border-radius: 20px;
  border: 1px solid #d2d2d2;
}
.ardCatD-head {
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ardCatD-head .left h2 {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 10px;
}
.ardCatD-head .left span {
  font-size: 24px;
  color: #ef4339;
}
.ardCatD-head .right {
  display: flex;
  align-items: center;
}
.ardCatD-head .right .img img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  border: 1px solid #e5eff0;
  margin-right: 10px;
}
.ardCatD-head .right .ardCatuser-d h3 {
  font-size: 24px;
  color: #1b262c;
  font-weight: bold;
  line-height: 38px;
}
.ardCatD-head .rating i {
  color: #9ba4b4;
  font-size: 15px;
}
.ardCatD-head .rating i.star-fill {
  color: #ff8805;
}
.ardCatD-head .rating .rating-count {
  margin-left: 5px;
  color: #646464;
  font-size: 19px;
  vertical-align: middle;
}
.ardCatD-body {
  border-top: 1px solid #d2d2d2;
}
.ardCatD-cat-block {
  display: flex;
  align-items: center;
}
.servicecat-item {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 25px 15px 25px 40px;
}
.servicecat-item:nth-child(2) {
  border: 1px solid #d2d2d2;
  border-top: 0;
}
.servicecat-item span,
.catD-lt-item .it-content span,
.ardCatD-content h3,
.ardCatD-content p,
.catPoint-item span,
.ardCatD-media-block h3 {
  font-size: 20px;
  display: block;
  color: #646464;
}
.servicecat-item span.scl,
.catD-lt-item .it-content span.itl,
.ardCatD-content h3,
.catPoint-item span:first-child,
.ardCatD-media-block h3 {
  font-weight: bold;
  color: #1b262c;
  margin-bottom: 10px;
}
.ardCatD-inner-body {
  padding: 25px 40px 30px 45px;
  position: relative;
}
.catD-lt-item:first-child {
  margin-bottom: 40px;
}
.catD-lt-item .icon {
  margin-right: 30px;
  min-width: 50px;
}
.ardCatD-location-time,
.catD-lt-item,
.catD-media-list {
  display: flex;
  align-items: center;
}

.ardCatD-location-time {
  justify-content: space-between;
}
.ardCat-submitQ-btn {
  width: 250px;
  display: block;
}
.ardCatD-content,
.catPoint-item,
.ardCatD-media-block {
  margin-top: 30px;
}
.ardCatD-content p {
  margin: 0;
  word-wrap: break-word;
}

.ardCatD-point-list {
  display: flex;
  flex-wrap: wrap;
}

.ardComment-item .ardc-bottom {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.ardComment-item .ardc-bottom span {
  display: block;
  margin-right: 30px;
  color: #9ba4b4;
  font-size: 18px;
}
.ardComment-item .ardc-bottom span i {
  color: #ff8805;
  margin-right: 4px;
}
.ardCatD-point-list .catPoint-item {
  width: 33.33%;
}
.ardCat-widget {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.ardCat-widget a {
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background: #ef4339;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(251, 189, 185, 1);
  -moz-box-shadow: 5px 5px 10px 0px rgba(251, 189, 185, 1);
  box-shadow: 5px 5px 10px 0px rgba(251, 189, 185, 1);
}
.ardCat-widget a + a {
  margin-top: 20px;
}
.ardCat-widget a img {
  width: 24px;
}
.ardCat-widget a span {
  display: block;
  color: #fff;
  font-size: 11px;
  margin-top: 3px;
  text-transform: uppercase;
}

.catD-media-list .catDM-item {
  margin-right: 20px;
  position: relative;
}
.catD-media-list .catDM-item img {
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}
.catDM-item .media-delete-btn {
  position: absolute;
  background: transparent;
  border: none;
  bottom: 4px;
  right: 4px;
  color: #fff;
}
.catDM-video {
  position: relative;
}
.catD-media-list .catDM-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.catDM-video span {
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  background: rgb(255 255 255 / 0.5);
  border-radius: 100px;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  text-align: center;
  line-height: 40px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.catDM-video span:hover {
  background: #ff8805;
  border-color: #ff8805;
}

.ardCat-list .mCSB_inside > .mCSB_container {
  margin-right: 15px;
}

.red-text-btn {
  color: #ef4339;
}

.rvq-block .red-text-btn {
  font-size: 20px;
}

.rvq-block .red-text-btn i {
  vertical-align: middle;
  margin-right: 2px;
}
.grayHero-tab-section {
  padding: 50px 0 0;
}
.grayHero-tab-section h1 {
  margin-bottom: 60px;
}
/* .filter-status-section {
    padding-top: 70px;
} */
.request-fs-block {
  padding: 20px 40px;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  justify-content: space-between;
}
.request-fs-block,
.requestFilter-block,
.search-city-form,
.sc-form-group {
  display: flex;
  align-items: center;
}
.nav-tabs {
  border: none;
}
.nav-tabs .nav-link {
  border: none;
}
.status-tabnav ul li {
  margin-right: 70px;
}
.status-tabnav ul li a {
  font-size: 20px;
  font-weight: 500;
  color: #1b262c;
  position: relative;
}
.status-tabnav ul li a:after {
  content: "";
  background: #ef4339;
  height: 3px;
  width: 0;
  display: block;
  margin: auto;
  position: absolute;
  bottom: -38px;
  left: 0;
  right: 0;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;
}
.status-tabnav ul li a.active:after {
  width: 50px;
}
.status-tabnav ul li a.active {
  color: #ef4339;
}
.serviceSearch-input-group {
  padding: 0 20px;
}
.serviceSearch-input-group input {
  width: 250px;
  padding-right: 0;
}
.sc-form-group input {
  border: none;
  background: transparent;
  border-radius: 0;
}
.sc-form-group input:focus {
  box-shadow: none;
}
.sc-form-group .zipCity-input {
  background: #e5eff0;
  color: #000;
  text-align: center;
}
.search-city-form {
  border: 1px solid #e5eff0;
  margin-right: 30px;
  border-radius: 5px;
}
.sc-form-group i {
  color: #0779e4;
  font-size: 20px;
}
.requestFilter-block .moreFilter-btn {
  width: 60px;
  height: 60px;
}

.grayHero-tabnav .nav-tabs li a {
  width: 220px;
  display: block;
  text-align: center;
  color: #000;
  background: #fff;
  padding: 18px 5px;
  font-size: 20px;
  font-weight: 600;
}

.grayHero-tabnav .nav-tabs {
  justify-content: center;
}
.grayHero-tabnav .nav-tabs li {
  overflow: hidden;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: -1px;
}
.grayHero-tabnav .nav-tabs li:first-child a {
  border-top-left-radius: 5px;
}
.grayHero-tabnav .nav-tabs li:last-child a {
  border-top-right-radius: 5px;
}
.grayHero-tabnav .nav-tabs li a.active {
  background: #ef4339;
  color: #fff;
}
.grayHero-tabnav.db-gh-tabNav .nav-tabs li a.active {
  border-color: #ef4339;
}

/****** Modal *****/
.modal-backdrop.show {
  display: none;
}

.scale_cus_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  outline: none;
  padding: 0px !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: rgb(0, 0, 0, 0.8);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

.scale_cus_modal.show {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.scale_cus_modal .scale_modal_body {
  background-color: #fff;
  padding: 30px;
  width: 100%;
  max-width: 490px;
  border-radius: 20px;
  margin: auto;
}
.scale_cus_modal .scale_modal_body.scale_modal_lg {
  max-width: 660px;
}
.scm_header .scm_head-title {
  text-align: center;
  margin-bottom: 30px;
  font-weight: normal;
}
.scm_footer {
  padding: 15px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.scm_footer.full-scm_footer {
  justify-content: center;
}
.scm_footer .btn {
  width: 180px;
  padding: 13px 5px;
}
.scm_footer.full-scm_footer .btn {
  width: 280px;
}
.confirmCancel p {
  margin: 0;
  font-size: 24px;
  padding: 10px 0 20px;
  text-align: center;
}

.field-note-text p {
  margin: 0;
  font-size: 15px;
  color: #9ba4b4;
}

.addNew-bank-alc .btn {
  padding: 0;
  font-size: 17px;
  display: block;
  margin-left: auto;
  color: #ef4339;
}

/******* Accept Completion *******/
.acceptCompletion-section {
  padding-bottom: 100px;
}
.acs-left-block {
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 20px;
  padding: 30px 45px;
  margin-right: 18px;
}
.acsOr-group span {
  color: #9ba4b4;
  display: block;
  padding: 20px 0;
}
.acs-left-block .form-group {
  margin-bottom: 30px;
}
.rsp-star-block {
  border: 1px solid #e5eff0;
  border-radius: 5px;
  height: 60px;
  padding: 6px 20px;
  background: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rsp-star-block .star-box i {
  font-size: 24px;
  color: #d5d5e5;
  margin-right: 20px;
}
.rsp-star-block .star-box .star-fill {
  color: #ff8805;
}
.rsp-star-block .orsp-text {
  color: #9ba4b4;
  font-size: 18px;
}
.ac-check-group .custom-check-block {
  border: 1px solid #e5eff0;
  border-radius: 5px;
  background: #fbfbfb;
}
.ac-check-group .custom-check-block .custom-check-label {
  padding-left: 0;
  font-size: 18px;
  color: #9ba4b4;
  margin: 0;
  width: 100%;
  height: 60px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
}

.custom-check-label {
  padding-left: 0;
  font-size: 18px;
  color: #9ba4b4;
  margin: 0;
  width: 100%;
  height: 60px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
}

.ac-check-group .custom-check-block .custom-check-label::before,
.ac-check-group .custom-check-block .custom-check-label::after {
  right: 20px;
  left: auto;
}
.ac-check-group .custom-check-block input:checked ~ .custom-check-label:before {
  right: 23px;
  left: auto;
}
.acc-group-list {
  display: flex;
  flex-wrap: wrap;
}
.acc-group-list .ac-check-group {
  width: calc(50% - 10px);
  margin-right: 20px;
}
.acc-group-list .ac-check-group:nth-child(even) {
  margin-right: 0;
}
.acc-group-list .ac-check-group:nth-child(1),
.acc-group-list .ac-check-group:nth-child(2) {
  margin-bottom: 20px;
}
.formSubmit-ac {
  text-align: center;
}
.formSubmit-ac .btn {
  width: 280px;
}
.ac-pro-box {
  border-radius: 20px;
  border: 1px solid #d2d2d2;
}
.accPro-figure .accPro-gray-box {
  background: #f6f6f6;
  border-bottom: 1px solid #d2d2d2;
  display: block;
  height: 120px;
}
.accPro-figure {
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}
.accPro-figure img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 1px solid #e5eff0;
  margin: -75px auto 0;
  display: block;
}
.accPro-detail h5 {
  text-align: center;
  font-size: 24px;
  color: #1b262c;
  font-weight: bold;
  margin-top: 15px;
}
.accPro-rate i {
  font-size: 17px;
}
.accPro-rate i.star-fill {
  color: #ff8805;
}
.accPro-rate span {
  margin-left: 7px;
  color: #646464;
  font-size: 20px;
}
.accPro-rate {
  text-align: center;
  margin-top: 5px;
}
.accPro-req-id {
  color: #ef4339;
  font-size: 20px;
  text-align: center;
  margin: 20px 0 40px;
  display: block;
}
.accPro-cal-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.accPro-cal-item span {
  color: #646464;
  font-size: 20px;
  display: block;
  min-width: 180px;
}
.accPro-cal-item h5 {
  font-size: 20px;
  font-weight: bold;
  display: block;
  text-align: right;
  width: 100%;
}
.bl-separator {
  min-width: 120px;
  border-bottom: 1px dashed #1b262c;
}
.accPro-calculation {
  padding: 0 40px;
}
.accPro-cal-total {
  border-top: 1px solid #d2d2d2;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.accPro-cal-total span,
.accPro-cal-total h5 {
  font-size: 24px;
  font-weight: bold;
}
.accPro-cal-note {
  color: #9ba4b4;
  font-size: 16px;
  padding: 0 40px 40px;
  margin-bottom: 0;
}
.accPro-cal-bottom {
  border-top: 1px solid #d2d2d2;
  padding: 30px 40px;
}
.accPro-cal-bottom .catD-lt-item {
  align-items: flex-start;
}

/******** My Prfile ***********/
.pdb-head-bg {
  height: 105px;
  display: block;
  background: url(../images/profile-head-bg.jpg) no-repeat center center/cover;
  border-radius: 20px 20px 0 0;
}
.pdb-head .pdb-head-img img {
  height: 150px;
  border: 1px solid #d2d2d2;
  width: 150px;
  border-radius: 100px;
}

.pdb-head-img {
  text-align: center;
  position: relative;
  display: table;
  margin: -75px auto 0;
}
.camera-row label {
  border: 1px solid #d2d2d2;
  background: #fbfbfb;
  height: 40px;
  width: 40px;
  border-radius: 100px;
  line-height: 40px;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  right: 0;
}
.camera-row label:hover {
  background: #ef4339;
  color: #fff;
  border-color: #ef4339;
}

.cpp-tab-inner {
  padding-bottom: 100px;
}
.cpp-white-box .status-tabnav {
  margin-bottom: 40px;
  padding-bottom: 20px;
}
.cpp-white-box .status-tabnav ul li a.active:after {
  bottom: -25px;
}
.cpp-white-box {
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 20px;
  padding: 30px 50px;
}
.cpp-form-block {
  display: flex;
  flex-wrap: wrap;
}
.cpp-white-box .form-group {
  margin: 0 10px 20px;
}
.pdb-body {
  padding: 20px 50px 30px;
}

/********* Transaction Page **********/
.transaction-tab-content {
  padding-bottom: 100px;
}
.transaction-bottom-block,
.transaction-invoice-book {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transaction-bottom-block {
  margin-top: 5px;
}

.transaction-bottom-block .price-box {
  color: #1b262c;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}
.transaction-invoice-book {
  margin-top: 35px;
}
.transaction-invoice-book .tib-label {
  font-weight: bold;
  color: #1b262c;
  margin-bottom: 10px;
  font-size: 20px;
  display: block;
}

.tib-left .updatePDF-down {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  width: 100px;
  height: 100px;
  background: #fbfbfb;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tib-left .updatePDF-down img {
  width: 62px;
}

.text-red {
  color: #ef4339;
}
.showTransaction-amount,
.showTransaction-right {
  display: flex;
  align-items: center;
}
.showTransaction-amount {
  border: 1px solid #d2d2d2;
  background: #f6f6f6;
  padding: 20px 40px;
  justify-content: space-between;
  margin-bottom: 30px;
  border-radius: 5px;
}
.showTransaction-left .balAmount-text {
  font-size: 20px;
  font-weight: bold;
}

.showTransaction-right h5 {
  font-size: 20px;
  font-weight: bold;
}
.transaction-filter {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  padding: 6px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px 30px 0;
}
.transaction-filter span {
  font-size: 22px;
  color: #1b262c;
  display: block;
}
.transaction-filter .moreFilter-btn {
  width: 60px;
  height: 60px;
}
.transaction-invoice-book .btn {
  padding: 14px 25px;
  font-size: 20px;
}

/*********** MY Feedback Page *********/
.myFeedback-list {
  border: 1px solid #d2d2d2;
  padding: 0 40px;
  border-radius: 20px;
  background: #fff;
}
.myFeedback-item {
  padding: 40px 0;
}
.myFeedback-item + .myFeedback-item {
  border-top: 1px solid #d2d2d2;
}
.mfi-top,
.mfi-top .mfiLeft {
  display: flex;
  align-items: center;
}
.mfi-top {
  justify-content: space-between;
}
.mfiLeft .mfiL-img img {
  height: 80px;
  width: 80px;
  border-radius: 100px;
  border: 1px solid #e5eff0;
  object-fit: cover;
}
.mfiLeft .mfiL-img {
  margin-right: 20px;
}
.mfiL-content h3 {
  font-size: 24px;
  color: #1b262c;
  font-weight: bold;
  margin-bottom: 2px;
}
.mfiL-content .rating i {
  font-size: 15px;
  color: #d5d5e5;
}
.mfiRight span {
  font-size: 20px;
}
.mfi-bottom {
  margin-left: 100px;
}
.mfi-bottom ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 10px 0 15px;
}
.mfi-bottom ul li {
  width: 22%;
  font-size: 20px;
}
.mfi-bottom ul li span {
  font-weight: bold;
}

.mfi-bottom strong {
  margin: 5px 0 15px 0;
  display: block;
  font-size: 20px;
}
.mfi-bottom strong {
  margin: 5px 0 15px 0;
  display: block;
  font-size: 20px;
}

.mfi-bottom a {
  display: inline-block;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #1b262c;
  position: relative;
}

.mfi-bottom a:after {
  content: "";
  display: block;
  background: #1b262c;
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 2px;
  transition: all 0.3s ease-in-out;
}

.mfi-bottom a:hover {
  color: #ff8805;
}
.mfi-bottom a:hover:after {
  background: #ff8805;
}
.mfi-des p {
  font-size: 20px;
  color: #646464;
  margin: 0;
}

/********** My Chat ********/
.messenger-list ul {
  padding: 0;
  /*height: calc(100vh - 65px);*/
}
.messenger-list ul li {
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 20px;
  padding: 20px;
  list-style: none;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.messenger-list ul li.selected,
.messenger-list ul li:hover {
  background: rgb(255 136 5 / 10%);
  border: 1px solid #ff8805;
}
.messenger-list ul li + li {
  margin-top: 20px;
}
.messenger-list .mCSB_inside > .mCSB_container {
  margin-right: 15px;
}
.messenger-list ul li .user-img {
  margin-right: 20px;
}
.messenger-list ul li .user-img img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  border: 1px solid #e5eff0;
}
.detail-left,
.detail-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-left {
  margin-bottom: 8px;
}
.user-details {
  width: 100%;
}
.detail-left .user-name {
  font-size: 24px;
  color: #1b262c;
  font-weight: bold;
}
.detail-left .last-message-date {
  font-size: 14px;
  color: #9ba4b4;
}
.detail-right .last-message {
  font-size: 20px;
  color: #646464;
}
.detail-right .unread-count {
  min-width: 40px;
  background: #ff8805;
  border-radius: 20px;
  text-align: center;
  color: #fff;
  line-height: 1;
  padding: 5px 0;
}
.messanger-message {
  border: 1px solid #d2d2d2;
  border-radius: 20px;
  overflow: hidden;
}
.chat-head {
  border-bottom: 1px solid #d2d2d2;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
}
.chatUser-left-head {
  display: flex;
  align-items: center;
}
.chatUser-left-head .chatUser-img img {
  border-radius: 100px;
  height: 80px;
  width: 80px;
  border: 1px solid #e5eff0;
}
.chatHead-user-details h5 {
  font-size: 24px;
  font-weight: bold;
  margin-left: 15px;
}
.chatUser-right-head span {
  display: block;
  font-size: 20px;
  color: #646464;
  text-align: right;
}
.chatUser-right-head span a {
  color: #646464;
}
.chatUser-right-head span a:hover {
  color: #ff8805;
}
.chatUser-right-head span:first-child {
  margin-bottom: 6px;
}

.messanger-message .chat-block {
  height: calc(100vh - 286px);
  padding: 15px 0;
}

.chat-block .recived-block,
.chat-block .sent-block {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 15px 30px;
  align-items: flex-end;
}
.chat-block .sent-img {
  margin-bottom: 21px;
}
.chat-block .recived-block .sent-img {
  margin-right: 10px;
}
.chat-block .sent-img img {
  border: 1px solid #e5eff0;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  object-fit: cover;
}
.chat-block .sent-message {
  border: 1px solid #d2d2d2;
  position: relative;
  padding: 12px 20px;
}
.chat-block .recived-block .sent-message {
  border-radius: 15px 15px 15px 5px;
}
.chat-block .sent-message p {
  margin: 0;
  color: #1b262c;
}
.chat-block span.sent-time {
  display: block;
  font-size: 14px;
  margin-top: 4px;
  color: #9ba4b4;
}
.chat-block .sent-block {
  flex-direction: row-reverse;
}
.chat-block .sent-block .sent-img {
  margin-left: 10px;
}
.chat-block .sent-block .sent-message {
  border-radius: 15px 15px 5px 15px;
  border-color: #e5eff0;
  background: #e5eff0;
}
.chat-block .sent-block .sent-message + .sent-message {
  margin-top: 10px;
}
.chat-block .sent-block span.sent-time {
  text-align: right;
}

.chat-date-time {
  font-size: 20px;
  text-align: center;
  color: #9ba4b4;
  margin: 15px 0;
}

.message-typing {
  display: flex;
  align-items: center;
  border-top: 1px solid #d2d2d2;
  height: 100px;
  padding-right: 25px;
  margin-top: auto;
  background: #ffffff;
}
.message-typing input.form-control {
  border: none;
  font-size: 18px;
  height: 100%;
  color: #646464;
  background: transparent;
  font-size: 18px;
}

.message-typing .send-btn {
  background: #ef4339;
  border-radius: 100px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: 60px;
  width: 60px;
  height: 60px;
}
.moreChat-show {
  text-align: center;
  margin-top: 30px;
}

.scale_cus_modal.changeProfile-wrap .scale_modal_body {
  max-width: 520px;
}
.cptg-item {
  border-radius: 5px;
  background: #fbfbfb;
  border: 1px solid #e5eff0;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.changeProfile-type-group {
  display: flex;
}
.changeProfile-type-group .cptg-item {
  width: 100%;
}
.changeProfile-type-group .cptg-item + .cptg-item {
  margin-left: 20px;
}
.cptg-title {
  color: #9ba4b4;
  font-size: 18px;
}
.e-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  margin: 0 !important;
  height: 28px;
}

.e-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.e-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.e-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.e-switch input:checked + .e-slider {
  background-color: #ef4339;
}

.e-switch input:focus + .e-slider {
  box-shadow: 0 0 1px #ef4339;
}

.e-switch input:checked + .e-slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}
/* Rounded sliders */
.e-slider.round {
  border-radius: 34px;
}

.e-slider.round:before {
  border-radius: 50%;
}

/********* People Voice Page *********/
.peopleVoice-page-section {
  position: relative;
  padding: 0px 0 100px;
}
.peopleVoice-item.video-block .video-img img {
  height: 384px;
}
.peopleVoice-item.video-block {
  margin: 30px 0;
}
.peopleVoice-col:nth-child(odd) {
  padding-right: 30px;
}
.peopleVoice-col:nth-child(even) {
  padding-left: 30px;
}

/******** Service Provider *********/
.req-text-gh {
  font-size: 18px;
  font-weight: bold;
}
.req-text-gh .orange-text {
  font-size: inherit;
}
.dotsDropdown-menu .dropdown-toggle:after {
  display: none;
}
.dotsDropdown-menu .dd-btn {
  background: transparent;
  border: none;
  color: #0779e4;
  font-size: 34px;
  padding: 0;
  line-height: 1;
}
.dotsDropdown-menu .dropdown-menu {
  border-color: #d2d2d2;
  margin: 0;
  padding: 0;
  min-width: 230px;
  left: auto !important;
  right: -15px;
  transform: none !important;
  top: calc(100% + 12px) !important;
}
.dotsDropdown-menu .dropdown-menu a {
  padding: 15px;
  display: block;
  color: #646464;
  font-size: 20px;
  line-height: 1.2;
}
.dotsDropdown-menu {
  position: relative;
}
.dotsDropdown-menu .dropdown-menu:after {
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #d2d2d2;
  position: absolute;
  top: -14px;
  right: 10px;
}

.pt-180 {
  padding-top: 180px;
}
.sp-rate-section .spdh-inner {
  align-items: initial;
}
.sp-rate-section .spdh-right {
  margin-top: 40px;
}
.ardCatD-head .dropDwon-quote-block.right {
  align-items: flex-end;
  flex-direction: column;
}
.ardCatD-head .dropDwon-quote-block.right .dotsDropdown-menu {
  margin-bottom: 20px;
}
.cmr-form-block .form-group.mc-rate-sp-group {
  margin-top: 20px;
  margin-bottom: 0;
}
.mc-rate-sp-group .ac-check-group {
  width: calc(25% - 15px);
}
.mc-rate-sp-group .ac-check-group:nth-child(2) {
  margin-right: 20px;
}
.request-dispute-content,
.warrant-req-content {
  margin-top: 50px;
}
.rdc-title {
  font-size: 26px;
  font-weight: bold;
}
.rdc-date {
  display: flex;
}

.rdc-date .rdcd-left {
  width: 50%;
}

.wrc-table {
  margin-top: 30px;
}
.wrc-head {
  background: #fbfbfb;
  border-left: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
}
.wrc-body {
  border: 1px solid #d2d2d2;
  border-top: 0;
}
.wrc-body .wrc-data {
  padding: 18px;
  color: #646464;
  font-weight: 300;
}
.wrc-row th.wrc-data {
  font-size: 20px;
  font-weight: 500;
  padding: 18px 20px;
  white-space: nowrap;
  border-bottom-width: 1px;
}

/*.myRequest-tab-content{*/
/*    padding-top: 50px;*/
/*}*/
.padding-top {
  padding-top: 50px;
}
.guestReqDetail-section {
  padding-top: 70px;
}
.feedback-detail-head {
  margin-bottom: 50px;
}
.feedback-detail-head h2 {
  font-weight: normal;
}
.feedback-detail-head h2 span {
  color: #ff8805;
  font-weight: bold;
}
.feedback-detail-head p {
  font-size: 24px;
  margin: 15px 0 0;
}
.feedback-detail-head p span {
  color: #ff8805;
}

.blankService-tab-go {
  text-align: center;
}
.blankService-tab-go .btn {
  font-size: 16px;
  font-weight: normal;
  padding: 8px 22px;
  margin: auto;
}
.cp-myProfile-tab-content .cpp-form-block {
  margin: 0 -10px;
}

.ardCat-list.transaction-scroll-box {
  height: 540px;
}
.ardCatD-right-space {
  margin-right: 80px;
}

.videoModal-box.scale_cus_modal .scale_modal_body {
  padding: 0;
  max-width: 700px;
}

.howItWork-read-block {
  margin-top: 30px;
}

/********** Pagination ********/
.e4-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
}

.e4p-right .e4p-group {
  display: flex;
  align-items: center;
}

.e4p-group span {
  display: block;
  white-space: nowrap;
  font-size: 20px;
  margin-right: 20px;
}

.e4p-group .form-control {
  border-color: #e5eff0;
  background-color: #fbfbfb;
  color: #000;
  padding: 12px 45px 12px 15px;
  height: 50px;
}

ul.pagination li.pageNumber.active a {
  border: 1px solid #e5eff0;
  background: #fbfbfb;
}

ul.pagination li a {
  display: inline-block;
  font-size: 18px;
  color: #1b262c;
  text-align: center;
}

ul.pagination li.pageNumber a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 5px;
  border: 1px solid #fff;
}

.e4-pagination .e4p-left ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

ul.pagination li:not(.pageNumber) .prev {
  margin-right: 30px;
}

ul.pagination li:not(.pageNumber) .next {
  margin-left: 30px;
}

ul.pagination li:not(.pageNumber) a.disable {
  color: #9ba4b4;
}

ul.pagination li:not(.pageNumber) .next i {
  margin-left: 10px;
  font-size: 15px;
}

ul.pagination li:not(.pageNumber) .prev i {
  margin-right: 10px;
  font-size: 15px;
}

/******** Content Pages ********/
.getContent-section {
  padding-bottom: 100px;
  position: relative;
}

.getContent-section .wp-clip-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: -120px;
}

.getContent-title h2 {
  font-size: 28px;
  margin-bottom: 18px;
}

.getContent-title h3 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: bold;
}

.t-upper {
  text-transform: uppercase;
}

.getContent-item p,
.getContent-item ul li {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 1.8;
}

.getContent-item p + p {
  margin-top: 30px;
}

.getContent-item p.f-24 {
  font-size: 24px;
}

.getContent-item {
  margin-top: 45px;
}

.getContent-item ul {
  padding-left: 15px;
}

.getContent-item ul li {
  margin-top: 14px;
}

.getContent-item ul li::marker {
  color: #ef4339;
}

.getContent-item .orange-text,
.getContent-item .orange-text span {
  color: #ef4339;
}

.gc-left {
  padding-left: 32px;
  margin-top: 32px;
}

.getContent-item h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 18px;
}

.gcl-item {
  margin-top: 30px;
}

.format-box {
  padding: 30px 22px;
}

.contactUs-form {
  padding: 35px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #d2d2d2;
  margin-right: 40px;
}

.emailUs-block h3 {
  font-size: 42px;
  margin-bottom: 5px;
}

.emailUs-block h3 a {
  color: #ff8805;
  font-weight: bold;
}

.emailUs-block h3 ~ a {
  font-size: 24px;
  color: #1b262c;
}

.emailUs-icon {
  margin-bottom: 30px;
}

.video-list-section.emailUs-section:before {
  width: 100%;
  height: 0;
  border-top: 188px solid white;
  border-left: 1920px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.video-list-section.emailUs-section {
  position: relative;
  padding-top: 160px;
}

/***** FAQ ********/
.faq-title h2 {
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.faq-accordion-wrap .faqCard {
  border-bottom: 1px solid #e5eff0;
}

.faq-accordion-wrap .btn-link {
  width: 100%;
  color: #000;
  padding: 15px 0;
  border: none;
  text-decoration: none;
}

.faqTab-title {
  font-size: 18px;
  line-height: 1.5;
  font-weight: normal;
  text-align: left;
  padding-right: 10px;
}
.accordion-upDown-icon {
  font-size: 20px;
  color: #9ba4b4;
}
.accordion-upDown-icon .fa-chevron-up {
  color: #ff8805;
}
.faq-card-body {
  padding-bottom: 1.25rem;
}

.faq-card-body p,
.faq-card-body ul li {
  margin: 0;
  font-weight: 300;
  font-size: 15px;
}

.faq-card-body p + p {
  margin-top: 10px;
}
.faq-card-body ul {
  padding-left: 18px;
  margin-top: 10px;
}

.faq-card-body ul li + li {
  margin-top: 6px;
}

.faq-card-body ul li::marker {
  color: #ff8805;
}

.faq-accordion-wrap .faqCard + .faqCard {
  margin-top: 20px;
}

.notifiy-check img {
  width: 100px;
}

.notifiy-check {
  margin-bottom: 30px;
}

.notifyCongrats-wrap.scale_cus_modal .scale_modal_body {
  max-width: 400px;
}

.notify-modal-body {
  text-align: center;
}

.notify-modal-body p {
  font-size: 18px;
}

.notifyPage-item + .notifyPage-item {
  margin-top: 20px;
}
.notifyPage-item a {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 20px;
  display: block;
  padding: 20px;
  display: flex;
}
.notifyPage-item a.active-np {
  background: rgb(255, 136, 5, 0.1);
  border-color: #ff8805;
  color: #000;
}

.notifyPage-item .active-np .np-content:after{
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 5px;
}

.np-content {
  position: relative;
}
.np-icon span {
  background: #ef4339;
  border-radius: 100px;
  border: 1px solid #e5eff0;
  height: 60px;
  width: 60px;
  display: block;
  text-align: center;
  line-height: 60px;
  font-size: 23px;
  color: #fff;
}
.np-icon {
  margin-right: 10px;
}
.np-content p {
  color: #646464;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 15px;
}
.np-content p strong {
  color: #1b262c;
  font-weight: bold;
}
.np-time {
  font-size: 15px;
  color: #9ba4b4;
}
.np-content .np-delete {
  position: absolute;
  color: #9ba4b4;
  bottom: 0;
  right: 0;
  border: none;
  padding: 0;
  background: transparent;
}

.npr-bottom {
  margin-top: 40px;
}

.npr-bottom h2 {
  margin-bottom: 30px;
}

.npr-bottom p {
  font-size: 21px;
  color: #1b262c;
}

.npr-bottom .btn {
  font-size: 18px;
  padding: 12px 36px;
  margin-top: 30px;
  width: 180px;
}
.npr-bottom .btn.notify-ac-btn {
  width: 280px;
}

.notifyPage-list {
  height: 530px;
}

/****** Start Earning *********/
.startEaring-tour-section .wp-clip-bg {
  position: absolute;
  top: -160px;
  left: 0;
  right: 0;
}

.startEaring-tour-section {
  position: relative;
  margin-top: -200px;
}

.startEaring-content .title h2 {
  font-size: 28px;
  margin-bottom: 22px;
}

.startEaring-content p {
  font-size: 18px;
}

.startEaring-content {
  width: 500px;
}

.startEaring-row:nth-child(odd) .startEaring-content {
  padding-left: 90px;
}

.se-line.se-right {
  text-align: right;
  margin-right: 46px;
}

.se-line.se-left {
  margin-left: 46px;
}

.se-line.se-top {
  margin-left: 30px;
}

.dotcircle-earn span {
  margin: auto;
  background: #9ba4b4;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  opacity: 0.2;
  display: block;
}
.enhanceProfile-section {
  padding: 120px 0 30px;
}
.enhanceProfile-inner {
  background: #0779e4;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0px 50px 0 90px;
}

.enhanceProfile-inner .eps-wave {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.enhanceProfile-img {
  margin-top: 60px;
}

.eps-left,
.eps-right {
  width: 50%;
  position: relative;
  z-index: 2;
}

.eps-left {
  margin-right: 50px;
}

.eps-left h2 {
  font-size: 42px;
  margin-bottom: 35px;
  color: #fff;
}

.eps-left h2 span {
  font-weight: bold;
}

.eps-left p {
  font-size: 18px;
  color: #fff;
  margin: 0;
  line-height: 1.8;
}

.eps-right {
  text-align: right;
}

.notify-list .mCSB_inside > .mCSB_container {
  margin: 0;
}

.footer-br ul li a.footer-active {
  color: #ff8805;
}

/****** SVG CSS *********/
.texwrite .item {
  visibility: hidden;
  display: none;
}
.texwrite .write {
  display: flex;
  align-items: flex-end;
}
.texwrite .write:after {
  content: "";
  width: 10px;
  height: 4px;
  display: block;
  background: black;
  color: orange;
  margin-bottom: 4px;
  margin-left: 2px;
  animation-duration: 350ms;
  animation-name: fade;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.tail {
  transform-box: fill-box;
  -webkit-animation: ccircle 5s infinite linear;
}
@-webkit-keyframes ccircle {
  0% {
    -webkit-transform: rotateZ(0deg);
  }
  50% {
    -webkit-transform: rotateZ(30deg);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
  }
}

.tail-home {
  transform-box: fill-box;
  transform-origin: top;
  -webkit-animation: ccircle2 5s infinite linear;
}
@-webkit-keyframes ccircle2 {
  0% {
    -webkit-transform: rotateZ(0deg);
  }
  50% {
    -webkit-transform: rotateZ(-10deg);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
  }
}

.ab-block.anim rect {
  fill: #ef4339;
}

.ab-block.anim g path {
  fill: #fff;
}

.rating-body.custom-scroll {
  height: 780px;
}

.mCustomScrollbar{
  scrollbar-color: rgb(255, 136, 5) transparent;
}

.about3col-section {
  position: relative;
  z-index: 3;
  padding-bottom: 100px;
}

.video-list-section.aboutVideoLIst-section {
  padding-top: 60px;
  margin-bottom: -397px;
  padding-bottom: 400px;
}

.coupon-fleid {
  position: relative;
}

.coupon-fleid input {
  padding-right: 74px;
}
.coupon-fleid .btn {
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  right: 10px;
  font-size: 15px;
}

/***** Price Range ********/
.slider-labels {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: -2px;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-stacking .noUi-handle {
  z-index: 10;
}
.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.noUi-horizontal {
  height: 4px;
}
.noUi-horizontal .noUi-handle {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  left: -7px;
  top: -4px;
  background-color: #fff;
  border: 1px solid #9ba4b4;
}
.noUi-background {
  background: #d2d2d2;
}
.noUi-connect {
  background: #ff8805;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}
.noUi-origin {
  border-radius: 2px;
  height: 6px;
}
.noUi-target {
  border-radius: 2px;
  margin-top: 10px;
}
.noUi-draggable {
  cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}
.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
/*.noUi-handle:active {
  border: 8px solid #19CCED;
  border: 8px solid rgb(25,204,237,0.38);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  left: -14px;
  top: -14px;
}*/
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #b8b8b8;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

.slider-drop {
  display: flex;
  width: 100%;
  margin-top: 9px;
}

.slider-drop span {
  min-width: 48px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #1b262c;
}

.slideDrop-item {
  width: 100%;
}

.slideDrop-item select.form-control {
  height: 30px;
  padding: 2px 10px;
  font-size: 14px;
  background-size: 12px;
}

.slideRange-dots {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.slideRange-dots span {
  width: 3px;
  height: 3px;
  background: #d2d2d2;
  border-radius: 100px;
  display: block;
}

.transaction-filter-section {
  position: absolute;
  z-index: 98;
  left: 15px;
  right: 15px;
  top: 100px;
}

.transaction-filter-section .cof-close-block {
  right: auto;
  left: 330px;
}

.rating-body .mCSB_inside > .mCSB_container {
  margin-right: 9px;
}

.model-hide {
  display: none;
}
.model {
display: block;
}

.text-ellipsis {
    word-break: break-all;
    text-overflow: ellipsis;
    /* max-width: 100%; */
    white-space: nowrap;
    overflow: hidden;
}

.disabled{
  opacity: 0.4;
}

.css-yk16xz-control{
  height: 100%;
}

.cursor-pointer{
  cursor: pointer;
}

 /* .slick-slide {
   margin-left:27px;
 } */

 .rah-input-wrapper{
   width: 100%;
 }

 .slick-slide{
   width: 200px;
 }

 /* submit-quote-home */
 .submit-quote-home{
   margin-left: 10px;
 }
